import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Auth from "services/auth";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import { useMaterialUIController } from "context";
import burceMars from "assets/images/bruce-mars.jpg";
import PrincipalState from "services/principal.state";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const [currentUser, setCurrentUser] = useState(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) setCurrentUser(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    authorize();
  }, []);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          //   ` url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {currentUser?.name ? currentUser.name : "Harshit Aggarwal"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {currentUser?.email}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static"></AppBar>
          </Grid>
          <MDBox p={2} mt="auto">
            <MDButton
              component="a"
              variant="gradient"
              color={sidenavColor}
              fullWidth
              onClick={() => {
                Auth.cleanAuth();
                Auth.remove("auth");
                enqueueSnackbar("Logged Out", {
                  variant: "success",
                });
                navigate("/login");
              }}
            >
              Log Out
            </MDButton>
          </MDBox>
        </Grid>
        {/* {children} */}
      </Card>
    </MDBox>
  );
}

export default Header;
