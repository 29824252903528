import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconButton, Tab, Tabs } from "@mui/material";
import MDTypography from "components/MDTypography";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";

function AddProject() {
  const location = useLocation();
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);
  const [project, setProject] = useState(null);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  const { setIsEdit, isEdit } = useOutletContext();

  let { id, reconcileId } = useParams();
  useEffect(() => {
    if (window.location.pathname === `/projects/${id}/reconciliations`) {
      setValue(2);
    } else if (window.location.pathname === `/projects/${id}/reconciliations/${reconcileId}`) {
      setValue(2);
    } else if (window.location.pathname === `/projects/${id}/reconciliations/new`) {
      setValue(2);
    } else if (window.location.pathname === `/projects/${id}/surveyList`) {
      setValue(1);
    } else if (window.location.pathname === `/projects/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/projects/new`) {
      setValue(0);
    }
  }, [location]);

  let getProject = async () => {
    try {
      let result = await TLinkApi.get(`/projects/${id}`);
      setProject(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getProject();
    }
  }, [id]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            navigate("/projects");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? (project && project?.name ? project?.name + "  " + "(" + id + ")" : id) : "New Project"}
      </MDTypography>

      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab label="Basic Details" index={0} component={Link} to={id ? `/projects/${id}/edit` : `/projects/new`} />
        <Tab
          label="Survey List"
          index={1}
          component={Link}
          disabled={!id}
          to={`/projects/${id}/surveyList
          
          `}
        />
        <Tab
          label="Reconciliation"
          index={2}
          component={Link}
          to={`/projects/${id}/reconciliations/new`}
          disabled={!id}
        />
      </Tabs>

      <Outlet
        context={{
          setIsEdit,
          isEdit,
        }}
      />
    </MDBox>
  );
}

export default AddProject;
