import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Projects() {
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  return <Outlet context={{ setIsEdit, isEdit }} />;
}

export default Projects;
