import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  ListSubheader,
  Autocomplete,
} from "@mui/material";
import ReactDOM from "react-dom";
import MDButton from "components/MDButton";

export const Modal = ({
  closeModal,
  onSubmit,
  languageMap,
  selectlanguage,
  defaultValue,
}) => {
  const [isvalidMinepc, setIsvalidMinepc] = useState(false);
  const [selectedlanguage, setSelectedlanguage] = useState("");
  const [isvalidLanguage, setisvalidLanguage] = useState(false);
  const [search, setSearch] = useState("");
  const [formState, setFormState] = useState(
    defaultValue || {
      isActive: "false",
      id: Math.floor(Math.random() * 10000 + 10000),
      languageDesc: "",
      minEpc: "",
      languageId: "",
    }
  );
  const [errors, setErrors] = useState("");
  useEffect(() => {
    console.log(formState);
  }, [formState]);

  const validateForm = () => {
    if (
      formState.minEpc &&
      !isNaN(formState.minEpc) &&
      formState.minEpc > 0 &&
      formState.languageId
    ) {
      setErrors("");
      setIsvalidMinepc(false);
      return true;
    } else {
      let errorFields = [];
      for (const [key, value] of Object.entries(formState)) {
        if (key === "minEpc" && (isNaN(value) || value <= 0 || value === "")) {
          setIsvalidMinepc(true);
        } else if (
          key === "minEpc" &&
          !isNaN(formState.minEpc) &&
          formState.minEpc > 0 &&
          formState.languageId
        ) {
          setIsvalidMinepc(false);
        }
        if (key === "languageId" && value === "") {
          setisvalidLanguage(true);
        } else if (key === "languageId" && value !== "") {
          setisvalidLanguage(false);
        }
      }
      return false;
    }
  };
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    return;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    formState.minEpc = Number(formState.minEpc).toFixed(2);
    onSubmit(formState);
    closeModal();
  };
  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  return ReactDOM.createPortal(
    <>
      <Grid
        sx={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(189, 189, 189,0.7)",
        }}
      ></Grid>
      <Grid
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Box className="modal">
          <FormControl>
            <Box
              sx={{
                height: "auto",
                width: "700px",
                padding: "10px",
                border: 1,
                borderColor: "#828385",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#fcfcfc",
              }}
            >
              <Grid
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "700px",
                }}
              >
                {defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959", margin: "10px" }}>
                      Edit Row
                    </h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                {!defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959", margin: "10px" }}>
                      Add Row
                    </h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                {!defaultValue && (
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    sx={{ margin: "7px 0px" }}
                    options={languageMap}
                    autoHighlight
                    onInputChange={(e, value) => { }}
                    onChange={(e, value) => {
                      if (value) {
                        setSelectedlanguage(value.languagedesc);
                        languageMap.map((lang) => {
                          if (lang.id === value.id) {
                            formState.languageDesc = lang.languagedesc;
                          }
                        });
                        setFormState({
                          ...formState,
                          ["languageId"]: value.id,
                        });
                      } else {
                        setFormState({
                          ...formState,
                          ["languageId"]: "",
                          ["languageDesc"]: "",
                        });
                      }
                    }}
                    getOptionLabel={(option) => option.languagedesc}
                    getOptionDisabled={(option) => {
                      if (selectlanguage.indexOf(String(option.id)) > -1) {
                        return true;
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.id}>
                        {option.languagedesc}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        error={isvalidLanguage}
                        helperText={
                          isvalidLanguage &&
                          (formState.languageId === ""
                            ? "Select a language"
                            : "")
                        }
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
                {defaultValue && (
                  <TextField
                    label="Language"
                    name="language"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                    value={formState.languageDesc}
                    inputProps={{ readOnly: true }}
                  />
                )}
                <TextField
                  label="Min Epc"
                  name="minEpc"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  value={formState.minEpc}
                  error={isvalidMinepc}
                  helperText={
                    isvalidMinepc &&
                    (formState.minEpc === ""
                      ? "Value cannot be empty"
                      : isNaN(formState.minEpc)
                        ? "Value should be numeric"
                        : formState.minEpc <= 0
                          ? "Value cannot be less than or equal to zero"
                          : "")
                  }
                />
                <Grid sx={{ display: "flex", justifyContent: "right" }}>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="dark"
                    variant="outlined"
                    type="submit"
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="info"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Box>
      </Grid>
    </>,
    document.querySelector("#root")
  );
};
