import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Users() {
  const [user, setUser] = useState({});
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  return <Outlet context={{ user, setUser, setIsEdit, isEdit }} />;
}

export default Users;
