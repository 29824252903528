import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Campaigns() {
  const [campaign, setCampaign] = useState({});
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  return <Outlet context={{ campaign, setCampaign, setIsEdit, isEdit }} />;
}

export default Campaigns;
