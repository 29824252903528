import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import typography from "assets/theme/base/typography";
import {
  Backdrop,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";
import { useState } from "react";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = React.useState(false);
  const [questionAnswer, setQuestionAnswer] = React.useState({});
  let questionText;
  if (questionAnswer) {
    if (questionAnswer.qualificationQuestion) {
      questionText = questionAnswer.qualificationQuestion.questionText;
    }
  }

  const tableStyle = { fontSize: "13.5px" };

  const fetchQuestionAnswer = async (qualId, langId) => {
    setLoadingQuestions(true);
    try {
      let data = await TLinkApi.get(`/qualifications/${langId}/${qualId}`);
      setQuestionAnswer(data);
      setLoadingQuestions(false);
    } catch (e) {
      console.log(e);
      setLoadingQuestions(false);
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={tableStyle}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={async () => {
              setOpen(!open);
              if (!open) {
                setLoadingQuestions(true);
                await fetchQuestionAnswer(row.id, props.languageId);
                setLoadingQuestions(false);
              }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={tableStyle}>
          {row.id}
        </TableCell>
        <TableCell align="left" sx={tableStyle}>
          {row.name}
        </TableCell>
        <TableCell align="left" sx={tableStyle}>
          {row.qualificationCategoryTypeId === "1" ? "Standard" : "Custom"}
        </TableCell>
        <TableCell align="center" sx={tableStyle}>
          {parseInt(row.qualificationTypeId) === 1
            ? "Multi Select"
            : parseInt(row.qualificationTypeId) === 2
            ? "Single Select"
            : "Text Input"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 2,
                background: "rgba(230, 247, 253, 1)",
                position: "relative",
              }}
            >
              {loadingQuestions && (
                <CircularProgress
                  size={20}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
              <Table
                size="small"
                aria-label="purchases"
                sx={{
                  background: "rgba(230, 247, 253, 1)",
                  "& th": {
                    fontSize: "14px",
                  },
                }}
              >
                <TableHead style={{ display: "table-header-group" }}>
                  <TableRow>
                    {questionAnswer && questionAnswer.qualificationQuestion && (
                      <TableCell sx={{ fontSize: "13.5px", fontWeight: "900" }}>
                        Q :&nbsp;{questionText}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {questionAnswer && questionAnswer.qualificationQuestion && (
                      <TableCell>
                        <Grid container spacing={2}>
                          {questionAnswer.qualificationQuestion.answers.map((value, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12} md={3} sx={tableStyle}>
                                {questionAnswer.qualificationTypeId === "1" ||
                                questionAnswer.qualificationTypeId === "2" ? (
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                      <MDTypography
                                        variant="button"
                                        sx={{
                                          cursor: "pointer",
                                          userSelect: "none",
                                          ml: 0.5,
                                          fontSize: "13.5px",
                                        }}
                                      >
                                        {value.qualificationAnswerDesc}
                                      </MDTypography>
                                    }
                                  />
                                ) : (
                                  <>
                                    <MDInput
                                      type="number"
                                      autoComplete="off"
                                      variant="outlined"
                                      label="min"
                                      size="small"
                                      fullWidth
                                      required
                                    />
                                    <MDInput
                                      type="number"
                                      autoComplete="off"
                                      variant="outlined"
                                      label="max"
                                      size="small"
                                      fullWidth
                                      required
                                    />
                                  </>
                                )}
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  qualification,
  isLoading,
  language,
  setLanguageId,
  languageId,
  setSearch,
  setCheck,
  categoryTypeId,
  setCategoryTypeId,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { info, textColor } = colors;
  const { size, fontWeightBold } = typography;
  const tableStyle = {
    color: textColor.main,
    typography: { size, fontWeightBold },
    fontSize: "5px",
    fontWeight: "700",
    textTransform: "uppercase",
    userSelect: "none",
  };

  const tableStyle1 = {
    fontSize: "13px",
    color: textColor.main,
    fontWeight: fontWeightBold,
    textTransform: "uppercase",
  };

  const [tab, setTab] = React.useState("Standard");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid
        container
        spacing={1}
        pb={2}
        pl={2}
        pr={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={2}>
          <MDTypography pt={2} color="textColor">
            <span style={{ display: categoryTypeId === "All" ? "none" : "" }}>
              {"Top 10"}
            </span>{" "}
            {"Qualifications"}
          </MDTypography>
        </Grid>

        <Grid
          item
          xs={12}
          md={10}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="right"
        >
          <Grid item xs={12} md={4}>
            <MDBox
              display="flex"
              justifyContent="right"
              pr={3}
              alignItems="center"
              flexDirection="row"
            >
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("All");
                  setCheck(true);
                  setCategoryTypeId("All");
                }}
                bgColor={tab === "All" ? "white" : ""}
                color={tab === "All" && `info`}
                style={{
                  border:
                    tab === "All"
                      ? `1px solid ${info.main}`
                      : "1px solid rgba(217, 217, 217, 1)",

                  cursor: "pointer",
                }}
              >
                All
              </MDBox>
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("Standard");
                  setCheck(false);
                  setCategoryTypeId("1");
                }}
                bgColor={tab === "Standard" ? "white" : ""}
                color={tab === "Standard" && `info`}
                style={{
                  border:
                    tab === "Standard"
                      ? `1px solid ${info.main}`
                      : "1px solid rgba(217, 217, 217, 1)",

                  cursor: "pointer",
                }}
              >
                Standard
              </MDBox>
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("Custom");
                  setCheck(false);
                  setCategoryTypeId("2");
                }}
                bgColor={tab === "Custom" ? "white" : ""}
                color={tab === "Custom" && `info`}
                style={{
                  border:
                    tab === "Custom"
                      ? `1px solid ${info.main}`
                      : "1px solid rgba(217, 217, 217, 1)",
                  cursor: "pointer",
                }}
              >
                Custom
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2} pr={3}>
            <FormControl fullWidth>
              <Select
                value={languageId}
                onChange={(e) => {
                  setLanguageId(e.target.value);
                }}
              >
                {language.map((value, i) => (
                  <MenuItem value={value.id} key={i}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <MDInput
              type="text"
              autoComplete="off"
              variant="outlined"
              label="Search"
              fullWidth
              size="small"
              required
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Card>
        <TableContainer
          component={Paper}
          sx={{ paddingTop: "20px", width: "100%" }}
        >
          <Table
            aria-label="collapsible table"
            style={{ color: "black" }}
            sx={{
              overflow: "auto",
              "& th": {
                fontSize: "14px",
              },
              "& .MuiTableCell-root": {
                fontSize: "14px",
                fontWeight: "400",
              },
            }}
          >
            <TableHead style={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell style={{ width: "2%" }} />
                <TableCell sx={tableStyle} style={{ width: "10%" }}>
                  Qualification Id
                </TableCell>
                <TableCell sx={tableStyle} align="left">
                  Qualification Name
                </TableCell>
                <TableCell sx={tableStyle} align="left">
                  Qualification Category
                </TableCell>
                <TableCell sx={tableStyle} align="center">
                  Qualification Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "black" }}>
              {qualification && qualification
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    tab={tab}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  />
                ))}
            </TableBody>
          </Table>
          <Grid item xs={12} p={2}>
            <TablePagination
              component="div"
              count={Math.round(qualification && (qualification.length / 10))}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Card>
    </>
  );
}
