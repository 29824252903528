import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Backdrop, CircularProgress, Stack, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetVendorsList from "hooks/vendorsList/GetVendorsList";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";

function VendorsList() {
  const navigate = useNavigate();
  const {
    vendors,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    isLoading,
    setEntriesPerPage,
    entriesPerPage,
  } = GetVendorsList();
  const { setIsEdit, setVendor } = useOutletContext();

  const tableTheme = useTableTheme();

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  useEffect(() => {
    setData(vendors);
  }, [vendors]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  let columns = [
    {
      accessorKey: "id",
      header: "Vendor Id",
      size: 50,
      align: "center",
      sortable: true,
      Cell: ({ cell }) => {
        const id = cell.getValue();
        return id ? (
          <div
            style={{ cursor: "pointer",marginLeft: "30px" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/vendors/${cell.getValue()}`);
            }}
          >
            {cell.getValue()}
          </div>
        ) : null;
      },
    },
    {
      accessorKey: "name",
      header: "Vendor Name",
      size: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      accessorKey: "phone",
      header: "Contact Number",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "type",
      header: "Type",
      size: 50,
      align: "center",
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        return row.original.isApi ? "Programmatic" : "Manual";
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/vendors/${row.original.id}`);
      },
      sx: {
        cursor: "pointer", 
      },
    }),
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { pagination },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
      },
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Vendors
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/vendors/new");
                    setVendor({});
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + New Vendor
                </MDButton>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  table={table}
                  style={{
                    width: "100%",
                  }}
                />
              </ThemeProvider>
            </Stack>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default VendorsList;
