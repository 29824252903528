import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import Colors from "../../../../assets/theme/base/colors";
import GetProjectsList from "hooks/ProjectList/GetProjectList";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";

const { formDetailsColor } = Colors;

function ProjectsList() {
  const navigate = useNavigate();
  const { setIsEdit } = useOutletContext();
  const tableTheme = useTableTheme();

  const {
    projects,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
  } = GetProjectsList();

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  useEffect(() => {
    setData(projects);
  }, [projects]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  let columns = [
    {
      accessorKey: "id",
      header: "Project Id",
      size: 150,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsEdit({
              disabled: true,
              edit: false,
            });
            navigate(`/projects/${cell.getValue()}`);
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "clientName",
      header: "Client Name",
      size: 150,
      Cell: ({ cell }) => {
        const clientName = cell.getValue();
        const truncatedName =
          clientName?.length > 15
            ? clientName.substring(0, 15) + "..."
            : clientName;
        return (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "name",
      header: "Project Name",
      size: 150,
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName =
          name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      size: 150,
      Cell: ({ cell }) => `${moment(cell.getValue()).format("DD-MM-YYYY")}`,
    },

    {
      accessorKey: "endDate",
      header: "End Date",
      size: 150,
      Cell: ({ cell }) => `${moment(cell.getValue()).format("DD-MM-YYYY")}`,
    },
    // {
    //   accessorKey: "totalRevenue",
    //   header: "Total Revenue",
    //   size: 150,
    //   Cell: ({ cell }) => {
    //     const revenue = parseFloat(cell.getValue());
    //     const roundedRevenue = Math.round(revenue * 100) / 100;
    //     return roundedRevenue.toFixed(2);
    //   },
    // },
    // {
    //   accessorKey: "totalCost",
    //   header: "Total Cost",
    //   size: 150,
    //   Cell: ({ cell }) => {
    //     const cost = parseFloat(cell.getValue());
    //     const roundedCost = Math.round(cost * 100) / 100;
    //     return roundedCost.toFixed(2);
    //   },
    // },
    {
      accessorKey: "status",
      header: "Status",
      align: "center",
      size: 150,
      Cell: ({ cell }) => {
        return cell.getValue() === 1
          ? "Live"
          : cell.getValue() === 2
            ? "Closed"
            : cell.getValue() === 3
              ? "Awarded"
              : cell.getValue() === 4
                ? "Paused"
                : cell.getValue() === 5
                  ? "Invoiced"
                  : cell.getValue();
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/projects/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { pagination },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
      },
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Projects
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <FormControl
                  size="small"
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={projectStatusFilter}
                    onChange={(e, value) => {
                      setProjecStatusFilter(e.target.value);
                    }}
                    sx={{ width: "200px" }}
                    label="Status"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    <MenuItem value={1}>Live</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                    <MenuItem value={3}>Awarded</MenuItem>
                    <MenuItem value={4}>Paused</MenuItem>
                    <MenuItem value={5}>Invoiced</MenuItem>
                  </Select>
                </FormControl>
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/projects/new");
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + New Project
                </MDButton>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  table={table}
                  style={{
                    width: "100%",
                  }}
                />
              </ThemeProvider>
            </Stack>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ProjectsList; 