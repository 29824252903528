import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReportStatusConfigList = () => {
  const [reportStatusConfig, setReportStatusConfig] = useState([]);
  const [reportStatusConfigMap, setReportStatusConfigMap] = useState([]);
  const getReportStatusConfig = async () => {
    try {
      let data = await TLinkApi.get("masters/status_config");
      setReportStatusConfig(data.data);
      let statusMap = [];
      data?.data?.map((value) => {
        statusMap[value.id] = value.name;
      });
      setReportStatusConfigMap(statusMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getReportStatusConfig();
  }, []);
  return { reportStatusConfig, getReportStatusConfig, reportStatusConfigMap };
};

export default GetReportStatusConfigList;
