import { Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

const Deduplication = (props) => {
  return (
    <MDBox
      sx={{
        border: "1px solid #BFBFBF",
        padding: "12px 12px 24px",
        borderRadius: "8px",
      }}
      bgColor="white"
      mt={2}
    >
      <MDTypography variant="h6" fontWeight="medium" color="textColor">
        {"Deduplication"}
      </MDTypography>
      <MDBox mt={0.5}>
        <hr style={{ border: "1px solid #e0e0e0", width: "100%" }} />
      </MDBox>
      <Grid container spacing={3} px={2} pt={2}>
        <Grid item xs={12} md={12} lg={4}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="dark" fontSize="12px">
                IP (Unique IP)
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5}>
              <Switch
                checked={props.uniqueIp}
                onChange={(e, value) => {
                  props.setValue("uniqueIp", value);
                }}
                disabled={props.isEdit.disabled}
              />
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="dark" fontSize="12px">
                Unique User
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5}>
              <Switch
                checked={props.uniqueUser}
                onChange={(e, value) => {
                  props.setValue("uniqueUser", value);
                }}
                disabled={props.isEdit.disabled}
              />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Deduplication;
