import { Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";
import { Controller } from "react-hook-form";

const OtherSecurity = ({
  researchDefender,
  countryMismatch,
  control,
  setValue,
  isEdit,
}) => {
  return (
    <MDBox
      sx={{
        border: "1px solid #BFBFBF",
        padding: "12px 12px 24px",
        borderRadius: "8px",
      }}
      bgColor="white"
      mt={2}
    >
      <MDTypography variant="h6" fontWeight="medium" color="textColor">
        {"Other Security"}
      </MDTypography>
      <MDBox mt={0.5}>
        <hr style={{ border: "1px solid #e0e0e0", width: "100%" }} />
      </MDBox>
      <Grid container spacing={3} px={2} pt={2}>
        <Grid item xs={12} md={12} lg={4}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                fontSize="12px"
              >
                Country mismatch
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5}>
              <Switch
                checked={countryMismatch}
                onChange={(e, value) => {
                  setValue("countryMismatch", value);
                }}
                disabled={isEdit.disabled}
              />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                fontSize="12px"
              >
                Research Defender
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5}>
              <Switch
                disabled
                checked={researchDefender}
                onChange={(e, value) => {
                  setValue("researchDefender", value);
                }}
                // disabled={isEdit.disabled}
              />
            </MDBox>
          </MDBox>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="dark"
            fontSize="12px"
          >
            Research Defender Score :&nbsp;&nbsp;
          </MDTypography>
          <Controller
            name="researchDefenderScore"
            control={control}
            render={({ field }) => (
              <MDInput
                {...field}
                sx={{ width: "50px" }}
                // disabled={isEdit.disabled}
                disabled
                autoComplete="off"
                type="number"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default OtherSecurity;
