import MDBox from "components/MDBox";
import TLinkApi from "services/tlink.api";
import React, { useEffect, useState } from "react";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import CollapsibleTable from "./components/qualificationTable";

function Qualification() {
  const [qualification, setQualification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [languageId, setLanguageId] = useState("1");
  const [categoryTypeId, setCategoryTypeId] = useState("1");
  const { language } = GetLanguageList();
  const [search, setSearch] = useState("");
  const [check, setCheck] = useState(false);

  let getQualificationList = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/qualifications/${categoryTypeId}?query=${search}&all=${check}`);
      setQualification(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getQualificationListAll = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/qualifications/?query=${search}&all=${check}`
      );
      setQualification(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (categoryTypeId === "All") {
      getQualificationListAll();
    } else {
      getQualificationList();
    }
  }, [search, check, categoryTypeId, languageId]);

  return (
    <MDBox py={2} mt={3} px={2}>
      <CollapsibleTable
        qualification={qualification}
        isLoading={isLoading}
        language={language}
        setLanguageId={setLanguageId}
        languageId={languageId}
        setSearch={setSearch}
        setCheck={setCheck}
        categoryTypeId={categoryTypeId}
        setCategoryTypeId={setCategoryTypeId}
      />
    </MDBox>
  );
}

export default Qualification;
