import React, { useState } from "react";
import { TextField, Button, Grid, Box, FormControl, InputAdornment } from "@mui/material";
import ReactDOM from "react-dom";
import MDButton from "components/MDButton";
import SupplierMargin from ".";

export const Modal = ({ closeModal, onSubmit, defaultValue, rows }) => {
  const [isvalidTocpi, setIsvalidTocpi] = useState(false);
  const [isvalidFromcpi, setIsvalidFromcpi] = useState(false);
  const [isvalidSuppliermargin, setIsvalidSuppliermargin] = useState(false);
  const [isduplicate, setIsDuplicate] = useState(false);

  const [formState, setFormState] = useState(
    defaultValue || {
      id: Math.floor(Math.random() * 10000 + 10000),
      from_cpi: "",
      to_cpi: "",
      supplier_margin: "",
    }
  );

  const [previoustate] = useState(defaultValue ? defaultValue : {});

  const validateForm = () => {
    if (
      formState.from_cpi &&
      formState.to_cpi &&
      formState.supplier_margin &&
      !isNaN(formState.from_cpi) &&
      !isNaN(formState.to_cpi) &&
      !isNaN(formState.supplier_margin) &&
      formState.from_cpi > 0 &&
      formState.to_cpi > 0 &&
      formState.supplier_margin > 0 &&
      Number(formState.to_cpi) > Number(formState.from_cpi) &&
      Number(formState.supplier_margin) <= 100
    ) {
      let response = true;
      setIsvalidTocpi(false);
      setIsvalidFromcpi(false);
      setIsvalidSuppliermargin(false);
      setIsDuplicate(false);
      rows.map((row) => {
        if (
          Number(row.from_cpi) === Number(formState.from_cpi) &&
          Number(row.to_cpi) === Number(formState.to_cpi)
        ) {
          setIsDuplicate(true);
          response = false;
        }
      });
      if (
        defaultValue &&
        Number(formState.from_cpi) === Number(previoustate.from_cpi) &&
        Number(formState.to_cpi) === Number(previoustate.to_cpi)
      ) {
        setIsDuplicate(false);
        response = true;
      }
      return response;
    } else {
      if (
        formState.from_cpi === "" ||
        formState.from_cpi <= 0 ||
        isNaN(formState.from_cpi)
      ) {
        setIsvalidFromcpi(true);
      } else {
        setIsvalidFromcpi(false);
      }
      if (
        formState.to_cpi === "" ||
        isNaN(formState.to_cpi) ||
        formState.to_cpi <= 0 ||
        Number(formState.from_cpi) >= Number(formState.to_cpi)
      ) {
        setIsvalidTocpi(true);
      } else {
        setIsvalidTocpi(false);
      }
      if (
        formState.supplier_margin === "" ||
        formState.supplier_margin <= 0 ||
        isNaN(formState.supplier_margin) ||
        formState.supplier_margin > 100
      ) {
        setIsvalidSuppliermargin(true);
      } else {
        setIsvalidSuppliermargin(false);
      }
      return false;
    }
  };

  const handleChange = (e) => {
    let input = e.target.value;
    setFormState({ ...formState, [e.target.name]: input });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    formState.to_cpi = Number(formState.to_cpi).toFixed(2);
    formState.from_cpi = Number(formState.from_cpi).toFixed(2);
    formState.supplier_margin = Number(formState.supplier_margin).toFixed(2);
    onSubmit(formState);
    closeModal();
  };
  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  return ReactDOM.createPortal(
    <>
      <Grid
        sx={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(189, 189, 189,0.7)",
        }}
      ></Grid>
      <Grid
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Box className="modal">
          <FormControl>
            <Box
              sx={{
                height: "auto",
                width: "700px",
                padding: "10px",
                border: 1,
                borderColor: "#828385",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#fcfcfc",
              }}
            >
              <Grid
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "700px",
                }}
              >
                {defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959" }}>Edit Row</h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                {!defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959" }}>Add Row</h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                <TextField
                  label="From Cpi"
                  name="from_cpi"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  value={formState.from_cpi}
                  error={isvalidFromcpi || isduplicate}
                  helperText={
                    (isvalidFromcpi || isduplicate) &&
                    (formState.from_cpi === ""
                      ? "Value cannot be empty"
                      : isNaN(formState.from_cpi)
                        ? "Value should be numeric"
                        : formState.from_cpi <= 0
                          ? "Value cannot be less than or equal to zero"
                          : isduplicate
                            ? "From Cpi and To Cpi already exist"
                            : "")
                  }
                />
                <TextField
                  label="To Cpi"
                  name="to_cpi"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  value={formState.to_cpi}
                  error={isvalidTocpi || isduplicate}
                  helperText={
                    (isvalidTocpi || isduplicate) &&
                    (formState.to_cpi === ""
                      ? "Value cannot be empty"
                      : isNaN(formState.to_cpi)
                        ? "Value should be numeric"
                        : formState.to_cpi <= 0
                          ? "Value cannot be less than or equal to zero"
                          : Number(formState.from_cpi) > Number(formState.to_cpi)
                            ? "To Cpi should always be greater than From Cpi"
                            : Number(formState.from_cpi) === Number(formState.to_cpi)
                              ? "To Cpi should not be equal to From Cpi"
                              : isduplicate
                                ? "To Cpi and From Cpi already exist"
                                : "")
                  }
                />
                <TextField
                  label="Supplier Margin %"
                  name="supplier_margin"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">%</InputAdornment>
                  //   ),
                  // }}
                  onChange={handleChange}
                  value={formState.supplier_margin}
                  error={isvalidSuppliermargin}
                  helperText={
                    isvalidSuppliermargin &&
                    (formState.supplier_margin === ""
                      ? "Value cannot be empty"
                      : isNaN(formState.supplier_margin)
                        ? "Value should be numeric"
                        : formState.supplier_margin <= 0
                          ? "Value cannot be less than or equal to zero"
                          : formState.supplier_margin > 100
                            ? "Value cannot be greater than 100"
                            : "")
                  }
                />
                <Grid sx={{ display: "flex", justifyContent: "right" }}>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="dark"
                    variant="outlined"
                    type="submit"
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="info"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Box>
      </Grid>
    </>,
    document.querySelector("#root")
  );
};
