import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetStudyTypeList = () => {
  const [studyType, setStudyType] = useState([]);
  const [studyTypeMap, setStudyTypeMap] = useState([]);
  const getStudyType = async () => {
    try {
      let data = await TLinkApi.get("/masters/studytype");
      setStudyType(data.data);
      let studyTypeMap = [];
      data.data.map((value) => {
        studyTypeMap[value.id] = value.name;
        return true;
      });
      setStudyTypeMap(studyTypeMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getStudyType();
  }, []);
  return { studyType, getStudyType, studyTypeMap };
};

export default GetStudyTypeList;
