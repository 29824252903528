import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const AllVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [vendorsMap, setVendorsMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const getVendors = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`vendors/vendor-list`);
      setVendors(data.data);
      setIsLoading(false);
      if (data.data) {
        let map = [];
        data?.data?.map((value) => {
          map[value.id] = value.name;
          return true;
        });
        setVendorsMap(map);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getVendors();
  }, [page, search]);
  return {
    vendors,
    getVendors,
    vendorsMap,
    isLoading,
    setPage,
    page,
    setSearch,
    search,
  };
};

export default AllVendors;
