import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";
import colors from "assets/theme/base/colors";

function AnswerText({ question, updateQuestion }) {
  return (
    <MDBox p={3} my={1} fontSize="15px" bgColor={colors.tableRow.data}  width="100%">
      <Grid container spacing={1} style={{ paddingLeft: "2px" }}>
        <Grid item xs={12} md={12} pb={2}>
          <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={1}>
            <MDBox display="flex" alignItems="center" ml={-2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
              >
                &nbsp;&nbsp;Q:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
              >
                &nbsp;&nbsp;{question.qualificationQuestion.questionText}&nbsp;
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={4} ml={-1}>
        <Grid item xs={12} md={5}>
          <MDInput
            type="text"
            fullWidth
            required
            value={question?.text}
            onChange={(e) => {
              question.text = e.target.value;
              updateQuestion("searchStatus", question);
            }}
            autoComplete="off"
            variant="outlined"
            label="Answer Text"
            error={question.text === "" ? true : false}
            helperText={question.text === "" ? "This field is Required" : ""}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AnswerText;
