import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import "../../global.css";

const SearchInput = ({ dashbord, setSearch, search, setMenuOpen }) => {
  return (
    <MDBox
      id="search"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      bgColor="white"
      height={dashbord === true ? "45px" : "40px"}
      border=" 1px solid #D9D9D9"
      borderRadius="8px"
      width={dashbord === true ? "50%" : "250px"}
      onClick={(e) => {
        if (dashbord) {
          e.stopPropagation();
          setMenuOpen(true);
        }
      }}
    >
      <input
        value={search}
        size="large"
        style={{
          border: "none",
          outline: "none",
          paddingLeft: dashbord === true ? "10px" : "5px",
          width: "100%",
        }}
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        borderLeft="1px solid #D9D9D9"
        padding="7px"
      >
        <Icon>search</Icon>
      </MDBox>
    </MDBox>
  );
};

export default SearchInput;
