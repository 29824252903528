import { Checkbox, Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import colors from "assets/theme/base/colors";

function QualificationChart(props) {
  const { grey } = colors;
  let { enqueueSnackbar } = useSnackbar();
  const [editError, setEditError] = useState(false);

  const editAns = async (value) => {
    if (!editError) {
      let qualifications = {};
      if (value.qualificationTypeId === 4) {
        let range = value.selectedRange.map((value) => {
          return `${value.min}-${value.max}`;
        });
        qualifications = {
          qualificationId: value.qualificationId,
          answerText: range,
          qualificationTypeId: value.qualificationTypeId,
        };
      } else if (value.qualificationTypeId === 3) {
        qualifications = {
          qualificationId: value.qualificationId,
          answerText: value?.text,
          qualificationTypeId: value.qualificationTypeId,
        };
      } else {
        qualifications = {
          qualificationId: value.qualificationId,
          answerId: value.answerId,
          qualificationTypeId: value.qualificationTypeId,
        };
      }

      try {
        let result = await TLinkApi.put(`survey/qualification/conditions/${value.surveyQualificationId}`, {
          qualifications,
        });
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        props.getQualificationQuestions();
        props.setI(null);
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (
      props.qualificationQuestions[props.index]?.text?.length > 0 &&
      props.qualificationQuestions[props.index]?.text[0] === ""
    ) {
      setEditError(true);
    } else if (props.qualificationQuestions[props.index]?.selectedRange?.length > 0) {
      let error = props.qualificationQuestions[props.index]?.selectedRange?.some(
        (val) =>
          val.min === "" ||
          val.max === "" ||
          Number(val.min) > Number(val.max) ||
          Number(val.min) < 10 ||
          Number(val.max) > 100
      );
      setEditError(error);
    } else if (props.val.answerId.length <= 0) {
      setEditError(true);
    } else {
      setEditError(false);
    }
  }, [props.qualificationQuestions]);

  return (
    <MDBox p={3} fontSize="15px" bgColor={colors.tableRow.data}>
      <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
        <Grid item xs={12} md={12}>
          {props.val?.qualificationTypeId === 1 || props.val?.qualificationTypeId === 2 ? (
            <Grid item xs={12} md={12} key={props.index}>
              <MDBox display="flex" alignItems="flex-start" gap="20px" mt={2}>
                <MDBox ml={-2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
                  >
                    &nbsp;&nbsp;Q:{props.index + 1}&nbsp;
                  </MDTypography>
                </MDBox>
                <MDBox ml={-1} flexWrap="wrap">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                      ml: -1.5,
                      mt: 0,
                    }}
                  >
                    &nbsp;&nbsp;{props.val?.question.questionText}&nbsp;
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container spacing={1} pt={1} style={{ paddingLeft: "5px" }}>
                <Grid item xs={12} md={12} mt={2}>
                  <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                    <MDBox display="flex" alignItems="center" marginLeft="6px">
                      <MDBox
                        style={{
                          border: `1px solid ${grey[500]}`,
                          borderRadius: "5px",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!props?.edit) {
                            if (props?.val?.answerId.length !== props?.singleQualification?.qualificationQuestion?.answers.length) {
                              props.val.answerId = [];
                              props?.singleQualification?.qualificationQuestion?.answers
                                .filter((option) => !props?.val?.answerId.includes(option.id))
                                .map((option, i) => {
                                  props.val.answerId.push(parseInt(option.id));
                                });
                            } else {
                              props.val.answerId = [];
                            }
                            let payload = [...props.qualificationQuestions];
                            payload[props.index] = props.val;
                            props.setQualificationQuestions(payload);
                          }
                        }}
                      >
                        {props?.val?.answerId.length === props?.singleQualification?.qualificationQuestion?.answers.length && (
                          <MDBox
                            bgColor="info"
                            style={{
                              borderRadius: "3px",
                              width: "10px",
                              height: "10px",
                            }}
                          ></MDBox>
                        )}
                      </MDBox>
                      &nbsp;&nbsp;Select All&nbsp;
                    </MDBox>
                    <Grid container spacing={2}>
                      {props?.singleQualification?.qualificationQuestion?.answers?.map((option, i) => {
                        return (
                          <Grid item xs={12} md={12} lg={6} key={i}>
                            <MDBox display="flex" alignItems="center" ml={3}>
                              <Checkbox
                                disabled={props?.edit === false}
                                checked={props.val.answerId.includes(parseInt(option.id))}
                                onChange={(e) => {
                                  let payload = [];
                                  if (!e.target.checked) {
                                    payload = [...props.qualificationQuestions];
                                    payload[props.index].answerId = payload[props.index].answerId.filter(
                                      (answer) => answer !== parseInt(option.id)
                                    );
                                  } else {
                                    payload = [...props.qualificationQuestions];
                                    payload[props.index].answerId.push(parseInt(option.id));
                                  }
                                  props.setQualificationQuestions(payload);
                                }}
                              />
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                                sx={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  ml: -1,
                                }}
                              >
                                &nbsp;&nbsp;{option.qualificationAnswerDesc}
                                &nbsp;
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        );
                      })}
                      {props.val.answerId.length <= 0 ? (
                        <MDBox color="error" px={3}>
                          Please select atleast one field
                        </MDBox>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          ) : props.val?.qualificationTypeId === 4 ? (
            <>
              <Grid container spacing={1} style={{ paddingLeft: "2px" }} key={props.index}>
                <Grid item xs={10} md={10} pb={2}>
                  <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={2}>
                    <MDBox display="flex" alignItems="center" ml={-2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
                      >
                        &nbsp;&nbsp;Q:{props.index + 1}&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          ml: -1.5,
                          mt: 0,
                        }}
                      >
                        &nbsp;&nbsp;{props.val?.question.questionText}&nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              {props?.val?.selectedRange?.map((rangeVal, i) => (
                <Grid container spacing={2} style={{ paddingLeft: "30px" }} mb={2} key={i}>
                  <Grid item xs={10} md={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          autoComplete="off"
                          variant="outlined"
                          label="min"
                          fullWidth
                          required
                          disabled={props?.edit === false}
                          value={rangeVal.min}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].min = e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          error={rangeVal.min === "" ? true : Number(rangeVal.min) < 10 ? true : false}
                          helperText={
                            rangeVal.min === ""
                              ? "This field is Required"
                              : Number(rangeVal.min) < 10
                              ? "This value can not be less then 10"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          fullWidth
                          required
                          disabled={props?.edit === false}
                          value={rangeVal.max}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].max = e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          label="max"
                          error={
                            rangeVal.max === ""
                              ? true
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? true
                              : Number(rangeVal.max) > 100
                              ? true
                              : false
                          }
                          helperText={
                            rangeVal.max === ""
                              ? "This field is Required"
                              : Number(rangeVal.max) > 100
                              ? "This value can not be greater than 100"
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? "This is not be less then min"
                              : ""
                          }
                        />
                      </Grid>
                      {i !== 0 && (
                        <Grid item xs={12} md={2}>
                          <IconButton
                            disabled={props?.edit === false}
                            onClick={() => {
                              let payload = [...props.qualificationQuestions];
                              payload[props.index].selectedRange.splice(i, 1);
                              props.setQualificationQuestions(payload);
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <MDBox display="flex" alignItems="center" justifyContent="flex-end" width="70%">
                <MDButton
                  variant="text"
                  color="dark"
                  disabled={props?.edit === false}
                  onClick={() => {
                    let payload = [...props.qualificationQuestions];
                    payload[props.index].selectedRange.push({
                      min: 10,
                      max: 100,
                    });
                    props.setQualificationQuestions(payload);
                  }}
                >
                  + add more input
                </MDButton>
              </MDBox>
            </>
          ) : (
            <>
              <Grid container spacing={1} style={{ paddingLeft: "2px" }} key={props.index}>
                <Grid item xs={10} md={10} pb={2}>
                  <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={2}>
                    <MDBox display="flex" alignItems="center" ml={-2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
                      >
                        &nbsp;&nbsp;Q:{props.index + 1}&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          ml: -1.5,
                          mt: 0,
                        }}
                      >
                        &nbsp;&nbsp;{props.val?.question.questionText}&nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingLeft: "30px" }}>
                <Grid item xs={10} md={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <MDInput
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        label="Answer Text"
                        fullWidth
                        required
                        disabled={props?.edit === false}
                        value={props?.val?.text[0]}
                        onChange={(e) => {
                          let payload = [...props.qualificationQuestions];
                          payload[props.index].text[0] = e.target.value;
                          props.setQualificationQuestions(payload);
                        }}
                        error={props.qualificationQuestions[props.index]?.text[0] === "" ? true : false}
                        helperText={
                          props.qualificationQuestions[props.index]?.text[0] === "" ? "This field is Required" : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12} display="flex" alignItems="center" justifyContent="flex-end">
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              disabled={props?.edit === false}
              onClick={() => {
                props.setEdit(!props.edit);
                editAns(props.val);
              }}
            >
              Save
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QualificationChart;
