import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

function GetSurveyStatusList() {
  const [surveyStatusList, setSurveyStatusList] = useState([]);
  const [surveyStatusMap, setSurveyStatusMap] = useState([]);
  const getSurveyStatusList = async () => {
    try {
      let data = await TLinkApi.get("/masters/statustype");
      setSurveyStatusList(data.data);
      if (data.data) {
        let statusMap = [];
        data.data.map((value) => {
          statusMap[value.id] = value.name;
          return true;
        });
        setSurveyStatusMap(statusMap);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getSurveyStatusList();
  }, []);
  return { surveyStatusList, getSurveyStatusList, surveyStatusMap };
}

export default GetSurveyStatusList;
