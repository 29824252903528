import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

import Colors from "../../assets/theme/base/colors";
const { formDetailsColor, background } = Colors;

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode, width } = ownerState;

  const { transparent, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  let backgroundValue = darkMode
    ? background.sidenav
    : `linear-gradient(14.47deg, ${background.default} 1.59%, ${background.default}  46.59%, ${background.default}  90.65%)`;

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue =
      `linear-gradient(0deg, ${background.default}  10.81%, ${background.default}  57.19%, ${background.default}  102.59%)`;
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    "&::-webkit-scrollbar": {
      display: "none",
    },
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: `1px 1px 8px ${formDetailsColor.primary}`,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      width: width,
      scrollBar: {
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
