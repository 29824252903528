import borders from "assets/theme/base/borders";

const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      height: `100%`,
      borderRadius: borderRadius.xl,
      border: "none",
      zIndex: 2,
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
