import React from "react";
import MarkdownReader from "./components/markdownReader";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Documentation() {
  return (

    <MDBox>
      <MDTypography
        color="textColor"
        sx={{ fontSize: "28px", fontWeight: "400", marginLeft: "25px" }}
      >
        User Documentation
      </MDTypography>
      <div style={{ marginLeft: "25px", marginRight: "25px", backgroundColor: "white", borderRadius: "10px", }}>
        <MarkdownReader />
      </div>
    </MDBox>
  );
}

export default Documentation;
