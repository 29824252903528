import MDBox from "components/MDBox";
import Header from "layouts/profile/components/Header";

function Overview() {
  return (
    <>
      <MDBox mb={2} />
      <Header></Header>
    </>
  );
}

export default Overview;
