import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";

function QuotaAnswerText({ question, updateQuestion, i, quotaQuestions, setQuotaError }) {
  useEffect(() => {
    if (quotaQuestions[i]?.text === "") {
      setQuotaError(true);
    } else {
      setQuotaError(false);
    }
  }, [quotaQuestions]);

  return (
    <MDBox p={2.5} fontSize="15px" bgColor="#E6F7FD" mt={1}>
      <Grid container spacing={1} style={{ paddingLeft: "2px" }}>
        <Grid xs={10} md={10} pb={2}>
          <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={1}>
            <MDBox display="flex" alignItems="center" ml={-2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
              >
                &nbsp;&nbsp;Q:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
              >
                &nbsp;&nbsp;{question.question.questionText}&nbsp;
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={4} ml={-1}>
        <Grid item xs={12} md={5}>
          <MDInput
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="Answer Text"
            fullWidth
            required
            value={question?.text}
            onChange={(e) => {
              let data = [...quotaQuestions];
              data[i].text = e.target.value;
              updateQuestion("quotaQuestions", data);
            }}
            error={quotaQuestions[i]?.text === "" ? true : false}
            helperText={quotaQuestions[i]?.text === "" ? "This field is Required" : ""}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuotaAnswerText;
