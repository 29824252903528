import { Backdrop, Grid, Icon, IconButton, CircularProgress, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import Deduplication from "./deduplicationSecurity";
import OtherSecurity from "./otherSecurity";

const SecurityConfig = () => {
  let navigate = useNavigate();
  const { setSurvey, setIsEdit, isEdit } = useOutletContext();
  const [confiqIsEdit, setConfigIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      maxLoi: "",
      minLoi: "",
      minLoiIsActive: false,
      maxLoiIsActive: false,
      researchDefenderScore: 0,
      uniqueIp: false,
      uniqueUser: false,
      countryMismatch: false,
      researchDefender: false,
    },
  });
  let { id } = useParams();

  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let countryMismatch = watch("countryMismatch");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  let { enqueueSnackbar } = useSnackbar();

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.minLoi = payload.minLoiIsActive ? parseInt(data.minLoi) : null;
    payload.maxLoi = payload.maxLoiIsActive ? parseInt(data.maxLoi) : null;
    payload.researchDefenderScore = parseInt(data.researchDefenderScore);
    if (confiqIsEdit === true) {
      try {
        let result = await TLinkApi.put(`survey/${id}/security`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        navigate(`/surveys/${id}/report/edit`);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setConfigIsEdit(false);
        setSurvey({});
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    } else {
      try {
        payload.surveyId = parseInt(id);
        let result = await TLinkApi.post("survey/security", payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/surveys/${id}/report/edit`);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setConfigIsEdit(false);
        setSurvey({});
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  const getSurveySecurityConfig = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/security`);
      if (result && Object.keys(result).length > 0) {
        setIsEdit({
          disabled: true,
          edit: false,
        });

        setConfigIsEdit(true);
        setValue("uniqueIp", result.uniqueIp);
        setValue("countryMismatch", result.countryMismatch);
        setValue("researchDefender", result.researchDefender);
        setValue("uniqueUser", result.uniqueUser);
        setValue("researchDefenderScore", result.researchDefenderScore);
        setValue("minLoi", result.minLoi);
        setValue("maxLoi", result.maxLoi);
        if (result.minLoi != null || result.maxLoi != null) {
          setValue("maxLoiIsActive", true);
          setValue("minLoiIsActive", true);
        } else {
          setValue("maxLoiIsActive", false);
          setValue("minLoiIsActive", false);
        }
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getSurveySecurityConfig();
  }, [id]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox sx={{ minHeight: `700px`, borderRadius: "16px" }} m={2} bgColor="white" p={2}>
          {isEdit.disabled === true && (
            <MDBox pt={2} px={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            </MDBox>
          )}
          <Deduplication uniqueIp={uniqueIp} uniqueUser={uniqueUser} setValue={setValue} isEdit={isEdit} />
          <OtherSecurity
            researchDefender={researchDefender}
            countryMismatch={countryMismatch}
            errors={errors}
            control={control}
            setValue={setValue}
            isEdit={isEdit}
          />
          <MDBox
            sx={{
              border: "1px solid #BFBFBF",
              padding: "12px 12px 24px",
              borderRadius: "8px",
            }}
            bgColor="white"
            mt={2}
          >
            <MDTypography variant="h6" fontWeight="medium" color="textColor">
              {"Validation"}
            </MDTypography>
            <MDBox mt={0.5}>
              <hr style={{ border: "1px solid #e0e0e0", width: "100%" }} />
            </MDBox>
            <Grid container spacing={3} px={2} pt={2}>
              <Grid item xs={12} md={12} lg={6} display="flex" alignItems="center" justifyContent="flex-start">
                <MDBox ml={0.5}>
                  <MDTypography variant="button" fontWeight="regular" color="dark" fontSize="12px">
                    Min LoI (%) :&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <Switch
                    checked={minLoiIsActive}
                    onChange={(e, value) => {
                      setValue("minLoiIsActive", value);
                    }}
                    disabled={isEdit.disabled}
                  />
                </MDBox>
                <Controller
                  name="minLoi"
                  control={control}
                  rules={{
                    required: minLoiIsActive ? "Min LoI (%) is required!" : false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      type="number"
                      fullWidth
                      required
                      sx={{ width: "200px" }}
                      {...register("minLoi", {
                        min: 0,
                        max: maxLoi,
                      })}
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled || !minLoiIsActive}
                      error={errors?.minLoi ? true : false}
                      helperText={
                        errors?.minLoi && errors.minLoi.message !== ""
                          ? errors.minLoi.message
                          : errors?.minLoi?.type === "min" || errors?.minLoi?.type === "max"
                          ? minLoiIsActive
                            ? "Check Min Value should be greater than max"
                            : null
                          : // : null
                          !minLoiIsActive && maxLoiIsActive
                          ? "Fill the value of min"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6} display="flex" alignItems="center" justifyContent="flex-start">
                <MDBox ml={0.5}>
                  <MDTypography variant="button" fontWeight="regular" color="dark" fontSize="12px">
                    Max LoI (%) :&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <Switch
                    checked={maxLoiIsActive}
                    onChange={(e, value) => {
                      setValue("maxLoiIsActive", value);
                    }}
                    disabled={isEdit.disabled}
                  />
                </MDBox>

                <Controller
                  name="maxLoi"
                  control={control}
                  rules={{
                    required: maxLoiIsActive ? "Max LoI (%) is required!" : false,
                  }}
                  // rules1={{
                  //   required: !maxLoiIsActive ? "Max LoI (%) is required!" : false,
                  // }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      sx={{ width: "200px" }}
                      type="number"
                      disabled={isEdit.disabled || !maxLoiIsActive}
                      {...register("maxLoi", {
                        min: minLoi,
                      })}
                      required
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.maxLoi ? true : false}
                      helperText={
                        errors?.maxLoi && errors.maxLoi.message !== ""
                          ? errors.maxLoi.message
                          : errors?.maxLoi?.type === "min"
                          ? maxLoiIsActive
                            ? "Check Max Value should be greater than Min"
                            : null
                          : // :null
                          minLoiIsActive && !maxLoiIsActive
                          ? "Fill the value of max"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled === false && (
          <Grid container spacing={0} px={2}>
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexDirection="row"
              gap="10px"
              mb={2}
            >
              <MDButton
                variant="outlined"
                color="dark"
                onClick={() => {
                  navigate("/surveys");
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                  setConfigIsEdit(false);
                  setSurvey({});
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={!(minLoiIsActive && maxLoiIsActive) && (minLoiIsActive || maxLoiIsActive)}
              >
                Save
              </MDButton>
              {/* <MDButton
  variant="gradient"
  color="info"
  type="submit"
  disabled={isEdit.disabled || (minLoiIsActive && !maxLoiIsActive)}
  required={minLoiIsActive && !maxLoiIsActive}
>
  Save
</MDButton> */}
            </Grid>
          </Grid>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              navigate("/surveys");
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setConfigIsEdit(false);
              setSurvey({});
            }}
          >
            &nbsp;cancel
          </MDButton>
          {/* {minLoiIsActive || maxLoiIsActive?( */}
          <MDButton
            variant="gradient"
            color="info"
            disabled={!maxLoiIsActive && !minLoiIsActive}
            // disabled={!minLoiIsActive || !maxLoiIsActive}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              navigate(`/surveys/${id}/report/edit`);
              setConfigIsEdit(false);
              setSurvey({});
            }}
          >
            Next
          </MDButton>
          {/* ):null} */}
        </MDBox>
      )}
    </>
  );
};

export default SecurityConfig;
