import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import TLinkApi from "services/tlink.api";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider, Tooltip } from "@mui/material";
import GetClientsList from "hooks/clientsList/GetClientsList";
import { Backdrop, CircularProgress } from "@mui/material";

function ClientsList() {
  const navigate = useNavigate();
  const { setIsEdit, setClient } = useOutletContext();
  const tableTheme = useTableTheme();

  const {
    clients,
    totalLength,
    setPage,
    setSearch,
    search,
    isLoading,
    setEntriesPerPage,
  } = GetClientsList();

  const [data, setData] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setData(clients);
  }, [clients]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  let columns = [
    {
      accessorKey: "id",
      header: "Client Id",
      size: 150,
      Cell: ({ cell }) => (
        <div
          style={{ cursor: "pointer", marginLeft: "30px" }}
          onClick={() => {
            setIsEdit({
              disabled: true,
              edit: false,
            });
            navigate(`/clients/${cell.getValue()}`);
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: "Client Name",
      size: 150,
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName =
          name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "phone",
      header: "Contact Number",
      Cell: ({ cell }) => {
        const phone = cell.getValue();
        return phone;
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      Cell: ({ cell }) => {
        const email = cell.getValue();
        return email;
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      Cell: ({ row }) => {
        const isApiClient = row.original.isApiClient;
        return isApiClient ? "Programmatic" : "Manual";
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/clients/${row.original.id}`);
      },
      sx: {
        cursor: "pointer", 
      },
    }),
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { pagination },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
      },
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Clients
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="10px"
              >
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/clients/new");
                    setClient({});
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + New Client
                </MDButton>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  table={table}
                  style={{
                    width: "100%",
                  }}
                />
              </ThemeProvider>
            </Stack>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ClientsList;
