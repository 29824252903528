import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReconciliationTypeList = () => {
  const [reconciliationType, setReconciliationType] = useState([]);
  const getIndustry = async () => {
    try {
      let data = await TLinkApi.get("/reconciliation/type-list");
      setReconciliationType(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getIndustry();
  }, []);
  return { reconciliationType };
};

export default GetReconciliationTypeList;
