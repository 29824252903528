import * as React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Paper,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import ReactDOM from "react-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function CustomQualification({
  CloseCustomQualification,
  langId,
  getQualificationQuestions,
  surveyId,
}) {
  const [qualificationType, setqualificationType] = React.useState("");
  const [qualificationmodalopen, setQualificationModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [qualificationName, setQualificationName] = useState("");
  const [qualificationQuestion, setQualificationQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [terminate, setTerminate] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  const [isQualNameError, setIsQualNameError] = useState(false);

  const [isQualTypeError, setIsQualTypeError] = useState(false);

  const [isQualQuestError, setIsQualQuestError] = useState(false);

  const [isOptionError, setIsOptionError] = useState(false);

  const showqualificationmodal = () => {
    setQualificationModalOpen(true);
  };
  const closequalificationmodal = () => {
    setQualificationModalOpen(false);
  };
  const handleChange = (event) => {
    setqualificationType(event.target.value);
  };

  const postCustomQualification = async () => {
    if (qualificationName === "") {
      setIsQualNameError(true);
      return;
    } else {
      setIsQualNameError(false);
    }

    if (qualificationType === "") {
      setIsQualTypeError(true);
      return;
    } else {
      setIsQualTypeError(false);
    }

    if (qualificationQuestion === "") {
      setIsQualQuestError(true);
      return;
    } else {
      setIsQualQuestError(false);
    }

    if (options.length <= 0) {
      setIsOptionError(true);
      return;
    } else {
      setIsOptionError(false);
    }

    setIsLoading(true);
    let answers = [];
    options.map((option) => {
      answers.push({
        isActive: true,
        languageId: langId,
        qualificationAnswerDesc: option,
      });
    });
    let obj = {
      name: qualificationName,
      qualificationTypeId: qualificationType,
      qualificationCategoryId: 7,
      qualificationCategoryTypeId: 2,
      isActive: true,
      qualificationQuestion: {
        isActive: true,
        questionText: qualificationQuestion,
        languageId: langId,
        answers: answers,
      },
    };
    // setIsLoading(true);
    try {
      let result = await TLinkApi.post(`qualifications`, obj);
      postCondition(result);
      //   setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      //   setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
    CloseCustomQualification();
  };

  const postCondition = async (data) => {
    let answers = [];
    data.data.qualificationQuestion.answers.map((answer) => {
      answers.push(answer.id);
    });
    let obj = {
      qualifications: {
        qualificationId: data.data.id,
        isActive: true,
        answerId: answers,
        qualificationTypeId: data.data.qualificationTypeId,
      },
      surveyId: surveyId,
    };
    console.log(obj);
    setIsLoading(true);
    try {
      let result = await TLinkApi.post(`survey/qualification/conditions`, obj);
      getQualificationQuestions();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  return ReactDOM.createPortal(
    <>
      <Grid
        sx={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(189, 189, 189,0.7)",
        }}
      ></Grid>
      <Grid
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Paper
          sx={{
            padding: "0px 10px 10px 10px",
            height: "500px",
            width: "600px",
            backgroundColor: "white",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Grid m={1}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
              sx={{
                justifyContent: "center",
                position: "sticky",
                top: "0px",
                paddingTop: "10px",
                alignItems: "center",
                background: "white",
                zIndex: 5,
              }}
            >
              <h5 style={{ color: "black" }}>Custom Qualification</h5>
              <hr style={{ backgroundColor: "black", height: "2px" }} />
            </Grid>
            <Grid>
              <MDInput
                label="Qualification Name"
                fullWidth
                sx={{ marginTop: "25px" }}
                onChange={(e) => {
                  setQualificationName(e.target.value);
                }}
                error={isQualNameError}
                helperText={
                  isQualNameError ? "Qualification Name is required!" : ""
                }
              ></MDInput>
              <FormControl fullWidth sx={{ marginTop: "25px" }}>
                <InputLabel id="select-label">Qualification Type</InputLabel>
                <Select
                  sx={{ borderRadius: "0px" }}
                  labelId="select-label"
                  id="select-id"
                  value={qualificationType}
                  label="Qualification Type"
                  onChange={handleChange}
                  error={isQualTypeError}
                  helperText={
                    isQualTypeError ? "Qualification Type is required!" : ""
                  }
                >
                  <MenuItem value={1}>Single Select</MenuItem>
                  <MenuItem value={2}>Multiple Select</MenuItem>
                </Select>
              </FormControl>
              <MDInput
                label="Qualification Question"
                fullWidth
                sx={{ marginTop: "25px" }}
                onChange={(e) => {
                  setQualificationQuestion(e.target.value);
                }}
                error={isQualQuestError}
                helperText={
                  isQualQuestError ? "Qualification Question is required!" : ""
                }
              ></MDInput>
              <Grid sx={{ marginTop: "10px", margin: "6px" }}>
                <span style={{ fontSize: "15px", fontWeight: "normal" }}>
                  {" "}
                  Qualification Answers{" "}
                </span>
                <hr
                  style={{
                    color: "black",
                    backgroundColor: "black",
                    height: "1px",
                    width: "160px",
                  }}
                />
                <FormGroup>
                  {options.map((option) => {
                    return (
                      <FormControlLabel
                        // control={
                        //   <Checkbox
                        //     onChange={() => {
                        //       // setTerminate();
                        //     }}
                        //   />
                        // }
                        control={<Checkbox defaultChecked />}
                        disabled
                        label={
                          <span
                            style={{ fontSize: "small", fontWeight: "normal" }}
                          >
                            {option}
                          </span>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
              <Grid container sx={{ margin: "6px" }}>
                <Grid item xs={8}>
                  <TextField
                    color="black"
                    label="Qualification Answer"
                    variant="standard"
                    value={value}
                    sx={{ width: "330px", marginTop: "5px" }}
                    onChange={(e) => setValue(e.target.value)}
                    error={isOptionError}
                    helperText={
                      isOptionError ? "Qualification Answer is required!" : ""
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (value !== "") {
                          setOptions([...options, value]);
                          setValue("");
                        }
                      }
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="10px"
              sx={{ position: "sticky", bottom: "10px" }}
            >
              <MDButton
                variant="outlined"
                sx={{ marginTop: "55px", marginRight: "5px" }}
                color="dark"
                onClick={() => CloseCustomQualification()}
              >
                cancel
              </MDButton>
              <MDButton
                variant="gradient"
                sx={{ marginTop: "55px", marginRight: "5px" }}
                color="info"
                onClick={() => {
                  // if (qualificationType !== "" && qualificationName !== "" && qualificationQuestion !== "" && options.length > 0) {
                  postCustomQualification();
                  // CloseCustomQualification();
                  // }
                }}
              >
                Save
              </MDButton>
            </MDBox>
          </Grid>
        </Paper>
      </Grid>
    </>,
    document.querySelector(".editsection")
  );
}
export default CustomQualification;
