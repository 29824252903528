import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IconButton, Tab, Tabs } from "@mui/material";
import MDTypography from "components/MDTypography";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";

function AddClients() {
  const { client, setClient, isEdit, setIsEdit } = useOutletContext();

  const location = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (window.location.pathname === `/clients/${id}/clientEncryption/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/clients/${id}/securityConfiguration/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/clients/${id}/clientFilters/edit`) {
      setValue(3);
    } else if (window.location.pathname === `/clients/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/clients/new`) {
      setValue(0);
    }
  }, [location]);

  let getClient = async () => {
    try {
      let result = await TLinkApi.get(`/clients/${id}`);
      setClient(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getClient();
    }
  }, [id]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            navigate("/clients");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? (client && client?.name ? client?.name + "  " + "(" + id + ")" : id) : "New Client"}
      </MDTypography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab label="Client Details" index={0} component={Link} to={id ? `/clients/${id}/edit` : `/clients/new`} />
        <Tab
          label="Client Encryption"
          index={1}
          component={Link}
          disabled={!id}
          to={`/clients/${id}/clientEncryption/edit`}
        />
        <Tab
          label="Security Config"
          index={2}
          component={Link}
          to={`/clients/${id}/securityConfiguration/edit`}
          disabled={!id}
        />
        <Tab
          label="Client Filters"
          index={3}
          sx={{ display: client && !client?.isApiClient ? "none" : "" }}
          component={Link}
          to={id ? `/clients/${id}/clientFilters/edit` : "/clients/new"}
          disabled={!id}
        />
      </Tabs>
      <Outlet context={{ client, setClient, setIsEdit, isEdit }} />
    </MDBox>
  );
}

export default AddClients;
