import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";

import Colors from "../../../../assets/theme/base/colors";

const { formDetailsColor, background } = Colors;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "vendorId",
    numeric: false,
    disablePadding: true,
    label: "Vendor Id",
  },
  {
    id: "clientId",
    numeric: true,
    disablePadding: false,
    label: "Client Id",
  },
  {
    id: "buyerId",
    numeric: true,
    disablePadding: false,
    label: "Buyer Id",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#828385",
      fontSize: 12,
      fontWeight: "bold",
    },
  }));

  return (
    <TableHead
      sx={{ display: "table-header-group", borderBottom: `2px solid #f0f0f0` }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const DisplayTable = ({ rows, deleteRow, editRow }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page] = useState(0);
  const [rowsPerPage] = useState(24);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#1a1a1a",
      fontSize: 11,
      fontWeight: "normal",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#1a1a1a",
      fontSize: 11,
    },
  }));

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          borderBottom: `1px solid #f0f0f0`,
        }}
        aria-label="customized table"
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {visibleRows.map((row, index) => (
            <TableRow key={row.id}>
              <StyledTableCell align="center">{row.vendorId}</StyledTableCell>
              <StyledTableCell align="center">{row.clientId}</StyledTableCell>
              <StyledTableCell align="center">
                {row.buyerId.map(
                  (buyer, index) =>
                    index < 5 && (
                      <Chip label={buyer.name} sx={{ marginLeft: "10px" }} />
                    )
                )}
                {row["buyerId"].length > 5 && (
                  <Chip
                    label={`${row["buyerId"].length - 5}+ more`}
                    sx={{ marginLeft: "10px" }}
                  />
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton size="small" onClick={() => editRow(row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => deleteRow(row.clientId)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {visibleRows.length <= 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{ color: `${formDetailsColor.primary}` }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Rows
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
