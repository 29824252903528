import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

function GetCalculationList() {
  const [calculationList, setCalculationList] = useState([]);
  const getCalculationList = async () => {
    try {
      let data = await TLinkApi.get("/masters/samplecal");
      setCalculationList(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCalculationList();
  }, []);
  return { calculationList, getCalculationList };
}

export default GetCalculationList;
