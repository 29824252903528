import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetUserList from "hooks/UsersList/GetUserList";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import MDTypography from "components/MDTypography";
import UserStatusList from "hooks/UerStatusList/UserStatusList";
function UsersList() {
  const navigate = useNavigate();
  const {
    user,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    isLoading,
    setEntriesPerPage,
    entriesPerPage,
  } = GetUserList();
  const { setIsEdit, setUser } = useOutletContext();
  // const { userStatusList } = UserStatusList();
  let rows = user;
  const userStatusList = [
    { id: 1, name: "Pending" },
    { id: 2, name: "Active" },
    { id: 3, name: "Deactivate" },
  ];
  let columns = [
    {
      accessor: "id",
      Header: "User Id",
      align: "left",
    },
    {
      accessor: "firstName",
      Header: "User Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const firstName = row?.values?.firstName;
        const truncatedName =
          firstName.length > 15
            ? firstName.substring(0, 15) + "..."
            : firstName;
        return (
          <Tooltip title={row?.values?.firstName}>
            <div>{`${truncatedName}`}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "email",
      Header: "Email",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "userStatusId",
      Header: "Status",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const userStatusId = row?.values?.userStatusId;

        // Find the corresponding status name based on userStatusId
        const statusObject = userStatusList.find(
          (status) => status.id === userStatusId
        );
        const userStatusName = statusObject ? statusObject.name : "Unknown";

        return (
          <Tooltip title={userStatusName}>
            <div>{userStatusName}</div>
          </Tooltip>
        );
      },
    },
  ];
  // console.log(columns, "rows");
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Users
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/users/new");
                    // setUser({});
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + Invite member
                </MDButton>
              </Grid>
            </Grid>
            <DataTable
              table={{ columns, rows }}
              isSorted
              noEndBorder
              entriesPerPage={entriesPerPage}
              canSearch={false}
              tablePage={page}
              navigateRow={"/users"}
              setTablePage={setPage}
              setEntriesPerPage={setEntriesPerPage}
              totalLength={totalLength}
              pagination={true}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default UsersList;
