import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetGlobalReconciliationList = ({ }) => {
  const [reconciliation, setReconciliation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalLength, setTotalLength] = useState();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const getList = async () => {
    try {
      let data = await TLinkApi.post(`/global/reconciliation/data`);
      setReconciliation(data.data.slice((page - 1) * entriesPerPage, page * entriesPerPage));
      setTotalLength(data.data.length);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getList();
  }, [page, entriesPerPage]);

  return {
    reconciliation,
    isLoading,
    setIsLoading,
    getList,
    totalLength,
    setPage,
    page,
    entriesPerPage,
    setEntriesPerPage,
  };
};

export default GetGlobalReconciliationList;
