import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import MDButton from "components/MDButton";
import Union2 from "assets/images/Union2.png";
import "../../global.css";
import csvDownload from "json-to-csv-export";
import TLinkApi from "services/tlink.api";
import { DatePicker } from "antd";
import moment from "moment";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetReconcileStatusList from "hooks/ReconcileStatus/GetReconcileStatusList";
import GetReportStatusConfigList from "hooks/ReportStatusConfig/GetReportStatusConfigList";
import GetReportSubStatusList from "hooks/ReportSubStatus/GetReportSubStatusList";
import GetListOfProjects from "hooks/ListOfProjects/GetListOfProjects";
import AllVendors from "hooks/listAllVendors/AllVendors";
import CancelIcon from "@mui/icons-material/Cancel";

import Colors from "../../assets/theme/base/colors";
const { formDetailsColor, info } = Colors;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Reports = () => {
  let navigate = useNavigate();
  let { clients } = GetListOfClients();
  let { reconcileStatus } = GetReconcileStatusList();
  const { vendors } = AllVendors();
  const { projects } = GetListOfProjects();
  const [isLoading, setIsLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [search, setSearch] = useState("");
  let { reportStatusConfig, reportStatusConfigMap } =
    GetReportStatusConfigList();
  let { reportSubStatus, reportSubStatusMap } = GetReportSubStatusList();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      surveyId: "",
      report: "",
      date: [],
      clientId: "",
      projectId: "",
      supplierId: "",
      isReconcilied: 1,
      statusConfig: [],
      subStatusConfig: [],
    },
  });

  let clientId = watch("clientId");
  let projectId = watch("projectId");

  const getSurveyList = async () => {
    try {
      let data = await TLinkApi.get(
        `/reports/survey-list/${clientId}?search=${search}`
      );
      if (projectId === "") {
        setSurveys(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSurveyListWithProjectId = async () => {
    try {
      let data = await TLinkApi.get(
        `/projects/${projectId}/survey-list?search=${search}`
      );
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllSurveyList = async () => {
    try {
      let data = await TLinkApi.get(
        `/reports/survey-list-search?search=${search}`
      );
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clientId) {
      getSurveyList();
    }
  }, [clientId, search]);

  useEffect(() => {
    if (projectId) {
      getSurveyListWithProjectId();
    }
  }, [projectId, search]);

  useEffect(() => {
    if (!clientId && !projectId) {
      getAllSurveyList();
    }
  }, [search]);

  let selectStyle = {
    minHeight: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      let res = await TLinkApi.post("reports/survey-report", data);
      setIsLoading(false);
      const dataToConvert = {
        data: res.data,
        filename: "Survey Report",
        delimiter: ",",
      };
      csvDownload(dataToConvert);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const handleStatusDelete = (e, value, onChange, selected) => {
    e.preventDefault();
    const updatedValue = selected.filter(
      (selectedValue) => selectedValue !== value
    );
    onChange(updatedValue);
  };
  const handleSubStatusDelete = (e, value, onChange, selected) => {
    e.preventDefault();
    const updatedValue = selected.filter(
      (selectedValue) => selectedValue !== value
    );
    onChange(updatedValue);
  };

  return (
    <MDBox py={2} mt={3} px={2} borderRadius="5px">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        pb={1}
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDBox display="flex" justifyContent="flex-start" alignItems="center">
          <IconButton
            onClick={() => {
              navigate(`/dashboard`);
            }}
          >
            <Icon color="dark">west_icon</Icon>
          </IconButton>
          <MDTypography
            color="textColor"
            sx={{ fontSize: "14px", fontWeight: "400" }}
          >
            {"Reports"}
          </MDTypography>
        </MDBox>
      </MDBox>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox mt={2} p={3} bgColor="white" borderRadius="16px">
          <MDBox
            sx={{
              border: "1px solid #BFBFBF",
              borderRadius: "8px",
            }}
            p={4}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="report"
                  control={control}
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                  rules={{
                    required: "Report is required !",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      error={errors?.report ? true : false}
                      required
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Select Report</InputLabel>
                      <Select value={value} onChange={onChange} sx={selectStyle} label="Select Report">
                        <MenuItem value="hit report">Hit Report</MenuItem>
                      </Select>
                      {errors?.report && (
                        <FormHelperText>{errors?.report ? errors.report.message : null}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="projectId"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      label="Project Id"
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Project Id</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        label="Project Id"
                        sx={selectStyle}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {projects.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="supplierId"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Supplier Id</InputLabel>
                      <Select
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Supplier id"
                        placeholder="Supplier Id"
                        sx={selectStyle}
                      >
                        {vendors?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="clientId"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Client Id</InputLabel>
                      <Select
                        value={value}
                        label="Client Id"
                        sx={selectStyle}
                        onChange={onChange}
                      >
                        {clients.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="surveyId"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Survey Id</InputLabel>
                      <Select
                        value={value}
                        sx={selectStyle}
                        onChange={onChange}
                        label="Survey Id"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        onClose={() => setSearch("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            required
                            value={search}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        {surveys.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            {value.surveyName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="date"
                  control={control}
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                  rules={{
                    required: "Date is required !",
                  }}
                  render={({ field: { onChange } }) => (
                    <>
                      <RangePicker
                        style={{ borderColor: errors?.date && "red" }}
                        format={dateFormat}
                        className={"createDateRangePicker"}
                        disabledDate={(current) => {
                          const isEmptyProjectId = !watch("projectId");
                          const isEmptySurveyId = !watch("surveyId");
                          if (isEmptyProjectId || isEmptySurveyId) {
                            return current && current < moment().subtract(3, 'months');
                          }
                        }}
                        onChange={(e, value) => {
                          if (value[0] === "" && value[1] === "") {
                            onChange([]);
                          } else {
                            onChange(value);
                          }
                        }}
                      />
                      {errors?.date && (
                        <FormHelperText
                          style={{ color: errors?.date && "red" }}
                        >
                          {errors?.date ? errors.date.message : null}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="isReconcilied"
                  control={control}
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Select Reconcile</InputLabel>
                      <Select value={value} onChange={onChange} sx={selectStyle} label="Select Reconcile">
                        {reconcileStatus.map((value, index) => (
                          <MenuItem value={value.id} key={index}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="statusConfig"
                  control={control}
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;

                    return (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`,
                            fontWeight: "400",
                          },
                        }}
                      >
                        <InputLabel>Select Status</InputLabel>
                        <Select
                          multiple
                          value={value}
                          onChange={onChange}
                          sx={selectStyle}
                          label="Select Status"
                          //input={<OutlinedInput />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={reportStatusConfigMap[value]}
                                  size="small"
                                  clickable
                                  deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                                  onDelete={(e) => handleStatusDelete(e, value, onChange, selected)}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {reportStatusConfig.map((value, index) => (
                            <MenuItem value={value.id} key={index}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="subStatusConfig"
                  control={control}
                  sx={{
                    label: {
                      color: `${formDetailsColor.primary}`,
                      fontWeight: "400",
                    },
                  }}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;

                    return (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`,
                            fontWeight: "400",
                          },
                        }}
                      >
                        <InputLabel>Select Sub Status</InputLabel>
                        <Select
                          multiple
                          value={value}
                          onChange={onChange}
                          sx={selectStyle}
                          label="Select Sub Status"
                          // input={<OutlinedInput />}
                          renderValue={(selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={reportSubStatusMap[value]}
                                  size="small"
                                  clickable
                                  deleteIcon={
                                    <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                  }
                                  onDelete={(e) =>
                                    handleSubStatusDelete(e, value, onChange, selected)
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {reportSubStatus.map((value, index) => (
                            <MenuItem value={value.id} key={index}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox
          mt={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            &nbsp;Cancel
          </MDButton>{" "}
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              window.location.reload();
            }}
          >
            &nbsp;Reset Form
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            <img src={Union2} width="15px" height="15px" style={{ paddingRight: "5px" }} alt="export" />
            Export
          </MDButton>
        </MDBox>
      </form>
    </MDBox>
  );
};

export default Reports;
