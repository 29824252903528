import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetSurveyBasicList from "hooks/CampaignReportList/GetCampaignReport";
import DataTable from "comps/Tables/DataTable";
import MDTypography from "components/MDTypography";
import moment from "moment";
function CampaignReportList() {
  const navigate = useNavigate();
  let { id } = useParams();
  const {
    campaignReport,
    isLoading,
    totalLength,
    setPage,
    page,
    setEntriesPerPage,
    entriesPerPage,
    setIsLoading,
  } = GetSurveyBasicList(id);
  const { setIsEdit, setCampaign } = useOutletContext();
  let rows = campaignReport;
  let columns = [
    {
      accessor: "campaignId",
      Header: "Id",
      align: "center",
      sortable: true,
    },

    {
      accessor: "campaignName",
      Header: "Name",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.campaignName;
        const truncatedName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        return (
          <Tooltip title={name}>
            <div
              onClick={() => {
                setIsEdit({
                  disabled: true,
                  edit: false,
                });
                navigate(`/surveys/${row.values.id}`);
              }}
            >
              {truncatedName}
              <br />
            </div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "surveyName",
      Header: "Survey Name",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.surveyName;
        // const truncatedName = name.length > 10 ? name.substring(0, 10) + "..." : name;
        return (
          <Tooltip title={name}>
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                maxWidth: "120px", // Adjust the maximum width as needed
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsEdit({
                  disabled: true,
                  edit: false,
                });
                navigate(`/surveys/${row.values.id}`);
              }}
            >
              {name}
            </div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "clientSurveyId",
      Header: "Client Survey Id",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "supplierName",
      Header: "Supplier Name",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.supplierName;
        const truncatedName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "supplierToken",
      Header: "Supplier Token",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "token",
      Header: "Token",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.token;
        const truncatedName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const startDate = moment(row?.values?.startDate);
        if (!startDate.isValid() || row?.values?.startDate === null) {
          return <div>NA</div>;
        }
        const formattedDateTime = startDate.format("Do MMM YYYY, h:mm:ss a");
        const truncatedName =
          formattedDateTime.length > 15
            ? formattedDateTime.substring(0, 15) + "..."
            : formattedDateTime;
        return (
          <Tooltip title={formattedDateTime}>
            <div style={{ width: "50px" }}>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "endDate",
      Header: "End Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const endDate = moment(row?.values?.endDate);
        if (!endDate.isValid() || row?.values?.endDate === null) {
          return <div>NA</div>;
        }
        const formattedDateTime = endDate.format("Do MMM YYYY, h:mm:ss a");
        const truncatedName =
          formattedDateTime.length > 15
            ? formattedDateTime.substring(0, 15) + "..."
            : formattedDateTime;
        return (
          <Tooltip title={formattedDateTime}>
            <div style={{ width: "50px" }}>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "statusName",
      Header: "Status",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.statusName;
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "subStatusName",
      Header: "Sub Status",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.subStatusName;
        const truncatedName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <MDBox width="100%">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDTypography color="textColor" ml="1%">
        Campaigns
      </MDTypography>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} my={3} borderRadius="5px" bgColor="white" width="100%">
          <Grid container spacing={2} pb={3}>
            <Grid
              px={2}
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  navigate("/campaigns/new");
                  setCampaign({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + New Campaign
              </MDButton>
            </Grid>
          </Grid>

          <DataTable
            table={{ columns, rows }}
            isSorted
            noEndBorder
            entriesPerPage={entriesPerPage}
            canSearch={false}
            tablePage={page}
            setTablePage={setPage}
            setEntriesPerPage={setEntriesPerPage}
            totalLength={totalLength}
            pagination={true}
            tableMaxHeight={"calc(100vh - 160px)"}
            rowWidth={"40px"}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default CampaignReportList;
