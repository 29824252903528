import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const SupplierMarginList = (id, setIsLoading) => {
  const [suppliers, setSuppliers] = useState([]);
  const [sup_id, setSup_id] = useState(id);
  useEffect(() => {
  }, [suppliers]);

  function createData(
    supplierId,
    fromCpi,
    toCpi,
    supplierMargin,
    createdBy,
    updatedBy
  ) {
    return {
      supplierId,
      fromCpi,
      toCpi,
      supplierMargin,
      createdBy,
      updatedBy,
    };
  }

  const postdata = async (data) => {
    try {
      setIsLoading(true);
      let result = await TLinkApi.post(`/vendors/margin/`, data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getdefaultdata = async () => {
    setIsLoading(true);
    if (sup_id === "") {
      setIsLoading(true);
    }
    try {
      setIsLoading(true);
      let data = await TLinkApi.get(`/vendors/margin/${sup_id}`);
      setIsLoading(false);
      setSuppliers(data.data);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const createdefaultdata = () => {
    const rows = [
      createData(id, 0.0, 1.0, 0.75, 1, 1),
      createData(id, 1.01, 1.25, 0.7, 1, 1),
      createData(id, 1.26, 1.5, 0.65, 1, 1),
      createData(id, 1.51, 1.75, 0.6, 1, 1),
      createData(id, 1.76, 2.0, 0.55, 1, 1),
      createData(id, 2.01, 3.0, 0.45, 1, 1),
      createData(id, 3.01, 4.0, 0.45, 1, 1),
      createData(id, 4.01, 5.0, 0.45, 1, 1),
      createData(id, 5.01, 6.0, 0.45, 1, 1),
      createData(id, 6.01, 7.0, 0.45, 1, 1),
      createData(id, 7.01, 8.0, 0.45, 1, 1),
      createData(id, 8.01, 9.0, 0.45, 1, 1),
      createData(id, 9.01, 10.0, 0.45, 1, 1),
      createData(id, 10.01, 11.0, 0.45, 1, 1),
      createData(id, 11.01, 12.0, 0.45, 1, 1),
      createData(id, 12.01, 13.0, 0.45, 1, 1),
      createData(id, 13.01, 14.0, 0.45, 1, 1),
      createData(id, 14.01, 15.0, 0.45, 1, 1),
      createData(id, 15.01, 16.0, 0.45, 1, 1),
      createData(id, 16.01, 17.0, 0.45, 1, 1),
      createData(id, 17.01, 18.0, 0.45, 1, 1),
      createData(id, 18.01, 19.0, 0.45, 1, 1),
      createData(id, 19.01, 20.0, 0.45, 1, 1),
      createData(id, 20.01, 1000.0, 0.45, 1, 1),
    ];
    rows.map((row) => {
      postdata(row);
    });
    getdefaultdata();
  };

  const getSuppliers = async () => {
    if (sup_id === "") {
      setIsLoading(true);
    }
    try {
      setIsLoading(true);
      let data = await TLinkApi.get(`/vendors/margin/${sup_id}`);
      if (data.data.length > 0) {
        setSuppliers(data.data);
        setIsLoading(false);
      } else {
        createdefaultdata();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [sup_id]);
  return {
    suppliers,
    sup_id,
    setSup_id,
  };
};

export default SupplierMarginList;
