import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetTopClientList = (timeline) => {
  const [topClients, setTopClients] = useState([]);
  const getTopClients = async () => {
    try {
      let data = await TLinkApi.post("dashboards/list/top-client", timeline);
      setTopClients(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTopClients();
  }, [timeline]);
  return { topClients, getTopClients };
};

export default GetTopClientList;
