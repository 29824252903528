import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IconButton, Tab, Tabs } from "@mui/material";
import MDTypography from "components/MDTypography";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";

function InviteMember() {
  const { user, setUser, isEdit, setIsEdit } = useOutletContext();
  const location = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (window.location.pathname === `/users`) {
      setValue(1);
    }
  }, [location]);

  let getUser = async () => {
    try {
      let result = await TLinkApi.get(`/users/${id}`);
      setUser(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [id]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            setUser({});
            navigate("/users");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id
          ? user && user?.firstName
            ? user?.firstName + "  " + user?.lastName + "  " + "(" + id + ")"
            : id
          : "New User"}
      </MDTypography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab label="User Details" index={0} component={Link} to={id ? `/users/${id}/edit` : `/users/new`} />
      </Tabs>
      <Outlet context={{ user, setUser, setIsEdit, isEdit }} />
    </MDBox>
  );
}

export default InviteMember;
