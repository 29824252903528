import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReportSubStatusList = () => {
  const [reportSubStatus, setReportSubStatus] = useState([]);
  const [reportSubStatusMap, setReportSubStatusMap] = useState([]);
  const getReconcileSubStatus = async () => {
    try {
      let data = await TLinkApi.get("masters/sub_status_config");
      setReportSubStatus(data.data);
      let subStatusMap = [];
      data?.data?.map((value) => {
        subStatusMap[value.id] = value.name;
      });
      setReportSubStatusMap(subStatusMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getReconcileSubStatus();
  }, []);
  return { reportSubStatus, getReconcileSubStatus, reportSubStatusMap };
};

export default GetReportSubStatusList;
