import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import colors from "../../../../assets/theme/base/colors";
const { formDetailsColor } = colors;

function VendorEncryption() {
  const navigate = useNavigate();
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      inComingEncryptionParam: "",
      inComingEncryptionEnable: false,
      inComingEncryptionKey: "",
      inComingEncryptionTypeId: "",

      outGoingEncryptionParam: "",
      outGoingEncryptionEnable: false,
      outGoingEncryptionKey: "",
      outGoingEncryptionTypeId: "",
    },
  });

  let inComingEncryptionEnable = watch("inComingEncryptionEnable");
  let outGoingEncryptionEnable = watch("outGoingEncryptionEnable");

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, [vendor]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/vendors/${id}/encryption`);
        setIsLoading(false);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setIsEdit({
            disabled: true,
            edit: false,
          });

          setValue("inComingEncryptionParam", result.data.inComingEncryptionParam);
          setValue("inComingEncryptionEnable", result.data.inComingEncryptionEnable);
          setValue("inComingEncryptionKey", result.data.inComingEncryptionKey);
          setValue("inComingEncryptionTypeId", result.data.inComingEncryptionTypeId);

          setValue("outGoingEncryptionParam", result.data.outGoingEncryptionParam);
          setValue("outGoingEncryptionEnable", result.data.outGoingEncryptionEnable);
          setValue("outGoingEncryptionKey", result.data.outGoingEncryptionKey);
          setValue("outGoingEncryptionTypeId", result.data.outGoingEncryptionTypeId);
        } else {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        }
      } catch (e) {
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        console.log(e);
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.vendorId = id;

    try {
      let result = await TLinkApi.put(`/vendors/${id}/encryption`, payload);
      setIsLoading(false);
      setIsEdit({
        disabled: false,
        edit: false,
      });
      setVendor({});
      if (vendor.isApi) navigate(`/vendors/${id}/routingConfig/edit`);
      else navigate(`/vendors`);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (inComingEncryptionEnable === false) {
      setValue("inComingEncryptionParam", "");
      setValue("inComingEncryptionKey", "");
      setValue("inComingEncryptionTypeId", "");
    }
  }, [inComingEncryptionEnable]);

  useEffect(() => {
    if (outGoingEncryptionEnable === false) {
      setValue("outGoingEncryptionParam", "");
      setValue("outGoingEncryptionKey", "");
      setValue("outGoingEncryptionTypeId", "");
    }
  }, [outGoingEncryptionEnable]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox bgColor="white" p={1} m={2} borderRadius="16px" height="700px">
          {isEdit.disabled === true && (
            <MDBox pt={2} px={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            </MDBox>
          )}
          <MDBox
            p={2}
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: "16px",
            }}
            m={2}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Incoming Encryption Settings"}
              </MDTypography>
              <Switch
                disabled={isEdit.disabled}
                checked={inComingEncryptionEnable}
                onChange={(e, value) => {
                  setValue("inComingEncryptionEnable", value);
                }}
              />
            </MDBox>
            {inComingEncryptionEnable && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionParam"
                    control={control}
                    rules={{
                      required: "Param Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Param"
                        error={errors?.inComingEncryptionParam ? true : false}
                        helperText={errors?.inComingEncryptionParam ? errors.inComingEncryptionParam.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionKey"
                    control={control}
                    rules={{
                      required: "Key Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Key"
                        error={errors?.inComingEncryptionKey ? true : false}
                        helperText={errors?.inComingEncryptionKey ? errors.inComingEncryptionKey.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: "Enycription Type Required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.main}`,
                            fontWeight: "400",
                          },
                        }}
                        required
                        error={errors?.inComingEncryptionTypeId ? true : false}
                      >
                        <InputLabel>Encryption Type</InputLabel>
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          label="Enycription Type"
                        >
                          <MenuItem value="1">SHA 256</MenuItem>
                          <MenuItem value="2">SHA 1</MenuItem>
                        </Select>
                        {errors?.inComingEncryptionTypeId && (
                          <FormHelperText>
                            {errors?.inComingEncryptionTypeId ? errors.inComingEncryptionTypeId.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
          <MDBox
            p={2}
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: "16px",
            }}
            m={2}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Outgoing Encryption Settings "}
              </MDTypography>
              <Switch
                disabled={isEdit.disabled}
                checked={outGoingEncryptionEnable}
                onChange={(e, value) => {
                  setValue("outGoingEncryptionEnable", value);
                }}
              />
            </MDBox>
            {outGoingEncryptionEnable && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionParam"
                    control={control}
                    rules={{
                      required: "Param Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        required
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Param"
                        error={errors?.outGoingEncryptionParam ? true : false}
                        helperText={errors?.outGoingEncryptionParam ? errors.outGoingEncryptionParam.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionKey"
                    control={control}
                    rules={{
                      required: "Key Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Key"
                        error={errors?.outGoingEncryptionKey ? true : false}
                        helperText={errors?.outGoingEncryptionKey ? errors.outGoingEncryptionKey.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: "Enycription Type Required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        required
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`,
                            fontWeight: "400",
                          },
                        }}
                        error={errors?.inComingEncryptionTypeId ? true : false}
                      >
                        <InputLabel>Encryption Type</InputLabel>
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          label="Enycription Type"
                        >
                          <MenuItem value="1">SHA 256</MenuItem>
                          <MenuItem value="2">SHA 1</MenuItem>
                        </Select>
                        {errors?.outGoingEncryptionTypeId && (
                          <FormHelperText>
                            {errors?.outGoingEncryptionTypeId ? errors.outGoingEncryptionTypeId.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        </MDBox>

        {isEdit.disabled !== true && (
          <MDBox px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
                navigate("/vendors");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              if (vendor.isApi) navigate(`/vendors/${id}/routingConfig/edit`);
              else navigate(`/vendors`);
            }}
          >
            Save
          </MDButton>
        </MDBox>
      )}
    </>
  );
}

export default VendorEncryption;
