import { Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import colors from "assets/theme/base/colors";

const QuestionBox = ({ question, updateQuestion }) => {
  const { grey } = colors;
  return (
    <MDBox p={3} my={1} fontSize="15px" bgColor={colors.tableRow.data}>
      <Grid container spacing={1} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={10}>
          <MDBox
            display="flex"
            alignItems="flex-start"
            gap="20px"
            flexDirection="row"
          >
            <MDBox ml={-2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: 0 }}
              >
                &nbsp;&nbsp;Q:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox flexWrap="wrap">
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                {question.qualificationQuestion.questionText}&nbsp;
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingLeft: "5px" }}>
        <Grid item xs={12} md={12} mt={2}>
          <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap">
            <MDBox display="flex" alignItems="center" marginLeft="6px">
              <MDBox
                style={{
                  border: `1px solid ${grey[500]}`,
                  borderRadius: "5px",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    question.selectedAnswers.length !== question.options.length
                  ) {
                    question?.options
                      .filter(
                        (val) => !question.selectedAnswers.includes(val.id)
                      )
                      .map((option, i) => {
                        question.selectedAnswers.push(option.id);
                        return true;
                      });
                  } else {
                    question.selectedAnswers = [];
                  }
                  updateQuestion("searchStatus", question);
                }}
              >
                {question.selectedAnswers.length ===
                  question.options.length && (
                  <MDBox
                    bgColor="info"
                    style={{
                      borderRadius: "3px",
                      width: "10px",
                      height: "10px",
                    }}
                  ></MDBox>
                )}
              </MDBox>
              &nbsp;&nbsp;Select All&nbsp;
            </MDBox>
            <Grid container spacing={2}>
              {question?.options.map((option, i) => (
                <Grid item xs={12} md={12} lg={6} key={i}>
                  <MDBox display="flex" alignItems="center" ml={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={question.selectedAnswers.includes(option.id)}
                          onChange={(e, value) => {
                            if (!e.target.checked) {
                              question.selectedAnswers =
                                question.selectedAnswers.filter(
                                  (answer) => answer !== option.id
                                );
                            } else {
                              question.selectedAnswers.push(option.id);
                            }
                            updateQuestion("searchStatus", question);
                          }}
                        />
                      }
                      label={
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="dark"
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{option.label}&nbsp;
                        </MDTypography>
                      }
                    />
                  </MDBox>
                </Grid>
              ))}
            </Grid>
            {question.selectedAnswers.length <= 0 ? (
              <MDBox color="error">Please select atleast one field</MDBox>
            ) : (
              ""
            )}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default QuestionBox;
