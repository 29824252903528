import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetProjectManagerList = () => {
  const [projectManager, setProjectManager] = useState([]);
  const [mangerSearch, setMangerSearch] = useState("");

  const getProjectManager = async () => {
    try {
      let data = await TLinkApi.get(`/users/managers?search=${mangerSearch}`);
      setProjectManager(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjectManager();
  }, [mangerSearch]);
  return {
    projectManager,
    getProjectManager,
    setMangerSearch,
    mangerSearch,
  };
};

export default GetProjectManagerList;
