import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReconciliationList = ({ id }) => {
  const [reconciliation, setReconciliation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const getIndustry = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/reconciliation/reconciliation-list/${id}?search=${search}&page=${page}&limit=${entriesPerPage}`
      );
      setReconciliation(data.data.reconciliations);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getIndustry();
  }, [id, page, search, entriesPerPage]);
  return {
    reconciliation,
    isLoading,
    setIsLoading,
    getIndustry,
    setSearch,
    search,
    setPage,
    page,
    totalLength,
    entriesPerPage,
    setEntriesPerPage,
  };
};

export default GetReconciliationList;
