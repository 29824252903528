import { Autocomplete, Icon, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import QuotaQualificationChart from "comps/Cards/quotaQulificationChart";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useForm } from "react-hook-form";

const QuotaList = ({
  index,
  quotaIndex,
  setQuotaIndex,
  data,
  value,
  setData,
  quota,
  getQuotaQualificationList,
  getQuotaList,
  isEdit,
  setIsEdit,
}) => {
  let { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(true);

  const activeQualification = async ({ value, val }) => {
    try {
      let result = await TLinkApi.put(`survey/quota/isActive/${value.quota.id}`, {
        isActive: val,
      });
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      getQuotaQualificationList();
      setQuotaIndex("");
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const save = async (payload) => {
    if (!error) {
      if (
        !isNaN(payload.quota.completes) &&
        parseInt(payload.quota.quotaTarget, 10) < parseInt(payload.quota.completes, 10)
      ) {
        enqueueSnackbar("Quota Target cannot be less than Quota Completes", {
          variant: "error",
        });
        return;
      }
      let conditions = payload.quota.conditions.map((val) => {
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          return {
            qualificationId: val.qualificationId,
            answerId: val.answerId,
          };
        } else if (val.qualificationTypeId === 4) {
          let range = val.selectedRange.map((value) => {
            return `${value.min}-${value.max}`;
          });
          return {
            qualificationId: val.qualificationId,
            answerText: range,
          };
        } else {
          return {
            qualificationId: val.qualificationId,
            answerText: [val.text],
          };
        }
      });
      let surveyQuota = {
        surveyId: payload.id,
        quota: {
          name: payload.quota.name,
          starts: payload.quota.starts,
          completes: payload.quota.completes,
          quotaTarget: payload.quota.quotaTarget,
          quotaCpi: payload.quota.quotaCpi,
          sampleNumber: payload.quota.sampleNumber,
          conditions: conditions,
          isActive: true,
        },
      };

      try {
        let result = await TLinkApi.put(`survey/quota/${payload.quota.id}`, surveyQuota);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });

        getQuotaQualificationList();
        getQuotaList();
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (
        data[index]?.quota?.name === "" ||
        data[index]?.quota?.starts === "" ||
        data[index]?.quota?.completes === "" ||
        data[index]?.quota?.quotaTarget === "" ||
        data[index]?.quota?.sampleNumber === "" ||
        data[index]?.quota?.conditions.length <= 0
      ) {
        setError(true);
      } else {
        let value = data[index]?.quota?.conditions.some((value) => {
          if (value?.answerId?.length <= 0 && value?.answerText?.length <= 0) {
            return true;
          } else if (value?.text === "") {
            return true;
          } else if (Array.isArray(value?.selectedRange) && value?.selectedRange?.length > 0) {
            let match = value?.selectedRange.some((rangeValue) => {
              if (
                rangeValue.min === "" ||
                rangeValue.max === "" ||
                Number(rangeValue.min) > Number(rangeValue.max) ||
                Number(rangeValue.min) < 10 ||
                Number(rangeValue.max) > 100
              ) {
                return true;
              } else {
                return false;
              }
            });
            return match;
          } else {
            return false;
          }
        });
        setError(value);
      }
    }
  }, [data]);

  return (
    <MDBox border="1px solid #D9D9D9" borderRadius="10px" mt={3} key={index}>
      <MDBox
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="center"
        minHeight={"80px"}
        gap="10px"
        mb={1}
      >
        <MDBox
          width="5%"
          sx={{ width: { md: "10%", lg: "5%" } }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mt={2.5}
        >
          <IconButton
            sx={{ transform: index === quotaIndex ? "rotate(180deg)" : "" }}
            onClick={() => {
              if (index === quotaIndex) {
                setQuotaIndex("");
              } else {
                setQuotaIndex(index);
              }
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </MDBox>
        <MDBox width="50%" sx={{ width: { xs: "30%", md: "40%", lg: "50%" } }}>
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.name === "" ? "Quota Name Required" : "Quota Name"}
          </MDBox>
          <MDInput
            fullWidth
            required
            disabled={edit === true}
            value={value.quota.name}
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.name = e.target.value;
              setData(payload);
            }}
            autoComplete="off"
            variant="outlined"
            type="text"
            placeholder="Quota Name"
            error={data[index]?.quota?.name === "" ? true : false}
          />
        </MDBox>

        <MDBox width="10%">
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.quotaTarget === "" ? "Quota Target Required" : "Quota Target"}
          </MDBox>
          <MDInput
            value={value.quota.quotaTarget}
            disabled={edit === true}
            fullWidth
            placeholder="Quota Target"
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.quotaTarget = e.target.value;
              setData(payload);
            }}
            required
            autoComplete="off"
            variant="outlined"
            type="number"
            error={data[index]?.quota?.quotaTarget === "" ? true : false}
          />
        </MDBox>

        <MDBox width="10%">
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.starts === "" ? "Starts Required" : "Starts"}
          </MDBox>
          <MDInput
            value={value.quota.starts}
            // disabled={isEdit.disabled === true}
            disabled
            fullWidth
            placeholder="Starts"
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.starts = e.target.value;
              setData(payload);
            }}
            required
            autoComplete="off"
            variant="outlined"
            type="number"
            error={data[index]?.quota?.starts === "" ? true : false}
          />
        </MDBox>

        <MDBox width="10%">
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.completes === "" ? "Completes Required" : "Completes"}
          </MDBox>
          <MDInput
            value={value.quota.completes}
            // disabled={isEdit.disabled === true}
            disabled
            fullWidth
            placeholder="Completes"
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.completes = e.target.value;
              setData(payload);
            }}
            required
            autoComplete="off"
            variant="outlined"
            type="number"
            error={data[index]?.quota?.completes === "" ? true : false}
          />
        </MDBox>

        <MDBox width="10%">
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.sampleNumber === "" ? "Remaining Target Required" : "Remaining Target"}
          </MDBox>
          <MDInput
            value={value.quota.quotaTarget - value.quota.completes}
            disabled
            fullWidth
            placeholder="Remaining Target"
            onChange={(e) => {}}
            required
            autoComplete="off"
            variant="outlined"
            type="number"
            error={data[index]?.quota?.sampleNumber === "" ? true : false}
          />
        </MDBox>

        {/* <MDBox width="10%">
          <MDBox
            style={{
              color: "textColor",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.quotaCpi === "" ? "CPI Required" : "CPI"}
          </MDBox>
          <MDInput
            value={value.quota.quotaCpi}
            disabled={edit === true}
            // disabled
                fullWidth
                placeholder="CPI"
                  onChange={(e) => {
                    let payload = [...data];
                    payload[index].quota.quotaCpi = e.target.value;
                    setData(payload);
                  }}
required
            autoComplete="off"
                  variant="outlined"
                  type="number"
            error={data[index]?.quota?.quotaCpi === "" ? true : false}
          />
        </MDBox> */}

        <MDBox
          width="10%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { md: "20%", lg: "10%" } }}
          mt={2.5}
        >
          <Switch
            checked={value.quota.isActive}
            // disabled={isEdit.disabled === true}
            onChange={(e, val) => {
              activeQualification({ value, val });
            }}
          />
          <IconButton
            color="dark"
            size="small"
            onClick={() => {
              // setIsEdit({
              //   disabled: false,
              //   edit: true,
              // });
              setEdit(!edit);
              if (index === quotaIndex) {
                setQuotaIndex("");
              } else {
                setQuotaIndex(index);
              }
            }}
          >
            <Icon>border_color_icon</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          marginLeft: { md: "11%", lg: "5.2%" },
          width: { md: "68%", lg: "85%" },
        }}
        display={index === quotaIndex ? "" : "none"}
        mb={1}
      >
        <Autocomplete
          multiple
          limitTags={1}
          disabled={edit === true}
          value={value.quota.conditions}
          onChange={(e, newValue) => {
            let payload = [...data];
            payload[index].quota.conditions = newValue;
            setData(payload);
          }}
          options={quota}
          getOptionLabel={(option) => (option?.question?.questionText ? option.question.questionText : "")}
          isOptionEqualToValue={(option, value) => option?.question?.questionText === value?.question?.questionText}
          sx={{
            "& .MuiInputBase-input": {
              minHeight: "20px",
            },
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              placeholder="Question List"
              size="small"
              error={data[index]?.quota?.conditions.length <= 0 ? true : false}
              helperText={data[index]?.quota?.conditions.length <= 0 ? "Question Required" : ""}
            />
          )}
        />
        {value?.quota?.conditions?.map((val, i) => (
          <QuotaQualificationChart
            key={i}
            val={val}
            value={value}
            data={data}
            quota={quota}
            index={i}
            dataIndex={index}
            setData={setData}
            setError={setError}
            isEdit={isEdit}
          />
        ))}
        <MDBox display="flex" alignItems="center" justifyContent="flex-end" flexDirection="row" mt={1}>
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            disabled={edit === true}
            onClick={() => {
              save(value);
            }}
          >
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default QuotaList;
