import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetClientsList = () => {
  const [clients, setClients] = useState([]);
  const [clientsMap, setClientsMap] = useState([]);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(50);

  const getClients = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/clients?search=${search}&page=${page + 1}&limit=${entriesPerPage}`);
      setClients(data.data.clientData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
      let clientMap = [];
      data?.data?.clientData.map((value) => {
        clientMap[value.id] = value.name;
        return true;
      });
      setClientsMap(clientMap);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getClients();
  }, [page, search, entriesPerPage]);
  return {
    clients,
    getClients,
    clientsMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
  };
};

export default GetClientsList;
