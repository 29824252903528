import MDBox from "components/MDBox";
import React, { useState } from "react";
import "./index.css";
function DashboardCard(props) {
  const [hover, setHover] = useState(false);
  const boxWidth = () => {
    if (hover) {
      return props.transformatiom;
    } else {
      return props.normalTransform;
    }
  };

  return (
    <MDBox>
      <div
        className="cardDetails"
        style={{
          background: hover ? props.hoverBackground : props.Colour,
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div className="borderWhite">
          <div
            className="xyz"
            style={{
              background: `${props.iconBackground}`,
              width: "40px",
              height: "40px",
              transform: boxWidth(),
            }}
          >
            <img src={props.Icon} width="24px" height="24px" alt="icon" />
          </div>
        </div>

        <div className="row">
          <div className="adjustment">
            <div className="column">
              <div className="statusDesign" style={{ color: `${props.statusColour}` }}>
                {props.Status}
              </div>
              <div className="valueDesign" style={{ color: `${props.nameColour}` }}>
                {props.Value}
              </div>
            </div>
            <div className="column">
              <div className="statusDesign" style={{ color: `${props.statusColour}` }}>
                {props.Maths}
              </div>
              <div className="valueDesign" style={{ color: `${props.nameColour}` }}>
                {props.MathsValue}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBox>
  );
}

export default DashboardCard;
