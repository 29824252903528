import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useForm, Controller } from "react-hook-form";

import Colors from "../../../../assets/theme/base/colors";
const { formDetailsColor } = Colors;

function ClientEncryption() {
  const navigate = useNavigate();
  const { client, setClient, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      variable1: "",
      variable2: "",
      variable3: "",
      variable4: "",

      inComingEncryptionParam: "",
      inComingEncryptionEnable: false,
      inComingEncryptionKey: "",
      inComingEncryptionTypeId: "",

      outGoingEncryptionParam: "",
      outGoingEncryptionEnable: false,
      outGoingEncryptionKey: "",
      outGoingEncryptionTypeId: "",
    },
  });

  let inComingEncryptionEnable = watch("inComingEncryptionEnable");
  let isActiveOutgoing = watch("outGoingEncryptionEnable");

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, [client]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/clients/config/${id}`);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setIsEdit({
            disabled: true,
            edit: false,
          });
          setValue("variable1", result.data.variable1);
          setValue("variable2", result.data.variable2);
          setValue("variable3", result.data.variable3);
          setValue("variable4", result.data.variable4);

          setValue("inComingEncryptionParam", result.data.inComingEncryptionParam);
          setValue("inComingEncryptionEnable", result.data.inComingEncryptionEnable);
          setValue("inComingEncryptionKey", result.data.inComingEncryptionKey);
          setValue("inComingEncryptionTypeId", result.data.inComingEncryptionTypeId);

          setValue("outGoingEncryptionParam", result.data.outGoingEncryptionParam);
          setValue("outGoingEncryptionEnable", result.data.outGoingEncryptionEnable);
          setValue("outGoingEncryptionKey", result.data.outGoingEncryptionKey);
          setValue("outGoingEncryptionTypeId", result.data.outGoingEncryptionTypeId);

          setDataEdit(true);
          setIsLoading(false);
        } else {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        }
      } catch (e) {
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        console.log(e);
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.clientId = id;

    try {
      if (dataEdit === true) {
        let result = await TLinkApi.put(`/clients/${id}/config`, payload);
        setDataEdit(false);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });

        setClient({});
        navigate(`/clients/${id}/securityConfiguration/edit`);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/clients/config", payload);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setClient({});
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });

        navigate(`/clients/${id}/securityConfiguration/edit`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
            minHeight: "700px",
          }}
          p={3}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mb={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Variables"}
            </MDTypography>
            <Grid container spacing={3} mt={1}>
              {/* First set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable1"
                    control={control}
                    rules={{
                      required: "Variable 1 is required!",
                    }}
                    render={({ field }) => (
                      <>
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          disabled={isEdit.disabled}
                          type="text"
                          autoComplete="off"
                          variant="outlined"
                          label="Variable 1"
                          error={errors?.variable1 ? true : false}
                          helperText={errors?.variable1 ? errors.variable1.message : null}
                        />
                        <Tooltip
                          title={
                            <div>
                              <span> Instruction:</span>
                              <br />
                              <span>Client Token Variable</span>
                            </div>
                          }
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  />
                </div>
              </Grid>

              {/* Second set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable2"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <>
                        <MDInput
                          {...field}
                          fullWidth
                          label="Variable 2"
                          disabled={isEdit.disabled}
                          type="text"
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Tooltip
                          title={
                            <div>
                              <span> Instruction:</span>
                              <br />
                              <span>Client User Id</span>
                            </div>
                          }
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  />
                </div>
              </Grid>

              {/* Third set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable3"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <>
                        <MDInput
                          {...field}
                          fullWidth
                          label="Variable 3"
                          type="text"
                          disabled={isEdit.disabled}
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Tooltip
                          title={
                            <div>
                              <span> Instruction:</span>
                              <br />
                              <span>Additional Variable if needed from client</span>
                            </div>
                          }
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  />
                </div>
              </Grid>

              {/* Fourth set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable4"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <>
                        <MDInput
                          {...field}
                          fullWidth
                          label="Variable 4"
                          type="text"
                          disabled={isEdit.disabled}
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Tooltip
                          title={
                            <div>
                              <span> Instruction:</span>
                              <br />
                              <span>Additional Variable if needed from client</span>
                            </div>
                          }
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mt={2}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Incoming Encryption Settings"}
              </MDTypography>
              <Switch
                disabled={isEdit.disabled}
                checked={inComingEncryptionEnable}
                onChange={(e, value) => {
                  setValue("inComingEncryptionEnable", value);
                }}
              />
            </MDBox>
            {inComingEncryptionEnable && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionParam"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        label="Param"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionKey"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        label="Key"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inComingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`,
                            fontWeight: "400",
                          },
                        }}
                      >
                        <InputLabel>Encryption Type</InputLabel>
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          label="Encryption Type"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                        >
                          <MenuItem value="1">SHA 256</MenuItem>
                          <MenuItem value="2">SHA 1</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mt={2}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Outgoing Encryption Settings"}
              </MDTypography>
              <Switch
                disabled={isEdit.disabled}
                checked={isActiveOutgoing}
                onChange={(e, value) => {
                  setValue("outGoingEncryptionEnable", value);
                }}
              />
            </MDBox>
            {isActiveOutgoing && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionParam"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        label="Param"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionKey"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        label="key"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outGoingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`,
                            fontWeight: "400",
                          },
                        }}
                      >
                        <InputLabel>Encryption Type</InputLabel>
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          label="Encryption Type"
                          onChange={onChange}
                        >
                          <MenuItem value="1">SHA 256</MenuItem>
                          <MenuItem value="2">SHA 1</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox px={2} pb={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setClient({});
                navigate("/clients");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>

      {isEdit.disabled === true && (
        <MDBox px={2} mb={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setClient({});
              navigate("/clients");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              setClient({});
              navigate(`/clients/${client.id}/securityConfiguration/edit`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      )}
    </>
  );
}

export default ClientEncryption;
