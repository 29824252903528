import { Outlet, useOutletContext } from "react-router-dom";
import React from "react";

function Reconciliation() {
  const { setIsEdit, isEdit } = useOutletContext();

  return <Outlet context={{ setIsEdit, isEdit }} />;
}

export default Reconciliation;
